import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import { useTranslation } from 'react-i18next';
import LoadingScreen from "../components/Common/LoadingScreen/LoadingScreen";

export const AuthenticationGuard = ({ component, ...args }) => {
  const { t } = useTranslation();

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div style={{ height: "90vh" }}>
        <LoadingScreen 
          header={t('authGuard.redirecting.header')} 
          text={t('authGuard.redirecting.text')} 
        />
      </div>
    ),
  });

  return <Component {...args} />;
};

export default AuthenticationGuard;
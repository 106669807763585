import React from 'react';

const Status = ({ progress }) => {
  let color, backgroundColor;

  switch (progress.toLowerCase()) {
    case 'completed':
      color = '#42BA60';
      backgroundColor = '#ECF8EF';
      break;
    case 'in progress':
      color = '#FFA043';
      backgroundColor = '#F9F2EA';
      break;
    case 'archived':
      color = '#484848';
      backgroundColor = '#ECECEC';
      break;
    default:
        color = '#484848';
        backgroundColor = '#ECECEC';
  }

  const style = {
    padding: '3px 16px',
    borderRadius: '7px',
    color: color,
    backgroundColor: backgroundColor,
    display: 'inline-block',
    fontSize: '0.75em',
    textAlign: 'center',
  };

  return <div style={style}>{progress}</div>;
};

export default Status;

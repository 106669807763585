import { ThemeProvider } from "@mui/material";
import { motion } from "framer-motion";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import FAQ from "../../pages/Home/faq/faq";
import { theme } from "../../theme";
import "./Products.css";

// Import your images statically to ensure they are bundled correctly
import egelIcon from "../../assets/images/Products/egel-icon.png";
import egelProd from "../../assets/images/Products/egel-prod.png";
import microscopyIcon from "../../assets/images/Products/microscopy-icon.png";
import microscopyProd from "../../assets/images/Products/microscopy-prod.png";
import petriIcon from "../../assets/images/Products/petri-icon.png";
import petriProd from "../../assets/images/Products/petri-prod.png";

function Products() {
    const { t } = useTranslation();
    const scrollRef = useRef();

    const productsData = [
        {
            id: "petri-dish-analysis",
            icon: petriIcon,
            image: petriProd,
            titleKey: "products.microbiology.title",
            textKey: "products.microbiology.text",
            includedKey: "products.microbiology.included",
        },
        {
            id: "microscopy",
            icon: microscopyIcon,
            image: microscopyProd,
            titleKey: "products.hematology.title",
            textKey: "products.hematology.text",
            includedKey: "products.hematology.included",
        },
        {
            id: "egel",
            icon: egelIcon,
            image: egelProd,
            titleKey: "products.drugDevelopment.title",
            textKey: "products.drugDevelopment.text",
            includedKey: "products.drugDevelopment.included",
        },
    ];

    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        const offset = -100;
        const offsetTop = section.offsetTop + offset;
        window.scrollTo({ top: offsetTop, behavior: "smooth" });
    };

    return (
        <ThemeProvider theme={theme}>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <div className="products-container">
                    <div className="section-content-center">
                        <h1 className="title">{t("products.mainTitle")}</h1>
                        <p className="description">{t("products.mainDescription")}</p>
                        <div className="product-options">
                            {productsData.map((product) => (
                                <div
                                    className="product-option"
                                    key={product.id}
                                >
                                    <a onClick={() => scrollToSection(product.id)}>
                                        <img
                                            src={product.icon}
                                            alt={t(product.titleKey)}
                                            className="product-icon"
                                        />
                                        <div className="product-icon-title">
                                            {t(product.titleKey)}
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>

                    {productsData.map((product, index) => (
                        <div
                            className="products-section"
                            id={product.id}
                            key={product.id}
                        >
                            {index !== 1 && (
                                <div className="product-img">
                                    <img
                                        src={product.image}
                                        alt={t(product.titleKey)}
                                    />
                                </div>
                            )}
                            <div className="section-content">
                                <h2 className="section-header">
                                    {t(product.titleKey)}
                                </h2>
                                <p>{t(product.textKey)}</p>
                                <ul>
                                    {t(product.includedKey, { returnObjects: true }).map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                            {index === 1 && (
                                <div className="product-img">
                                    <img
                                        src={product.image}
                                        alt={t(product.titleKey)}
                                    />
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                <FAQ />
            </motion.div>
        </ThemeProvider>
    );
}

export default Products;
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Avatar, AvatarGroup } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Alert } from "@mui/material";
import { SendInvite } from "../../../services/project.service";
import "./MemberSection.css";
import MultiEmailField from "../../../components/MultiEmailField/MultiEmailField";
import { ColorButton } from "../../../theme";

const MemberSection = ({
  projectName,
  projectMembers,
  accessToken,
  projectID,
  projectCreator,
  userID,
}) => {
  const { t } = useTranslation();
  const [inviteEmails, setInviteEmails] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [memberPopupSeverity, setMemberPopupSeverity] = useState("");
  const [memberPopupMessage, setMemberPopupMessage] = useState("");

  const togglePopup = () => {
    setIsPopupOpen((prev) => !prev);
  };

  const sendInvite = () => {
    if (!inviteEmails) {
      setMemberPopupSeverity("error");
      setMemberPopupMessage(t('memberSection.errors.noEmail'));
      setTimeout(() => {
        setMemberPopupSeverity("");
        setMemberPopupMessage("");
      }, 3000);
      return;
    }

    const emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(inviteEmails)) {
      setMemberPopupSeverity("error");
      setMemberPopupMessage(t('memberSection.errors.invalidEmail'));
      setTimeout(() => {
        setMemberPopupSeverity("");
        setMemberPopupMessage("");
      }, 3000);
      return;
    }

    let formData = new FormData();
    formData.append("email", inviteEmails);
    formData.append("user_id", userID);
    formData.append("project_id", projectID);
    formData.append("project_name", projectName);

    SendInvite(accessToken, formData).then((response) => {
      if (response.error || response.data.error) {
        setMemberPopupSeverity("error");
        setMemberPopupMessage(
          response.error?.message ||
            response.data.error ||
            t('memberSection.errors.inviteFailed')
        );
        setTimeout(() => {
          setMemberPopupSeverity("");
          setMemberPopupMessage("");
        }, 3000);
      } else {
        setMemberPopupSeverity("success");
        setMemberPopupMessage(t('memberSection.success.inviteSent'));
        setTimeout(() => {
          setMemberPopupSeverity("");
          setMemberPopupMessage("");
        }, 3000);
      }
    });
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
        fontSize: "large",
        width: "fit-content",
      }}
    >
      {t('memberSection.members')}:
      <AvatarGroup max={4}>
        {projectMembers.length > 0 ? (
          projectMembers.map((member, index) => (
            <Avatar key={index} alt={member.name} src={member.picture} />
          ))
        ) : (
          <p>{t('memberSection.noMembers')}</p>
        )}
      </AvatarGroup>
      <MoreHorizIcon className="more-icon" onClick={togglePopup} />
      {isPopupOpen && (
        <div className="members-popup">
          <div className="members-popup-header">
            <p className="members-popup-title">{t('memberSection.members')}</p>
            <div className="members-popup-close" onClick={togglePopup}>
              <p>X</p>
            </div>
          </div>
          <div>
            {projectCreator.toString() === userID && (
              <div className="members-popup-content">
                <MultiEmailField
                  emails={inviteEmails}
                  setEmails={setInviteEmails}
                  hideLabel={true}
                />
                <ColorButton
                  onClick={sendInvite}
                  variant="contained"
                  style={{ width: "100%" }}
                >
                  {t('memberSection.invite')}
                </ColorButton>
              </div>
            )}
            <div className="members-popup-content">
              {memberPopupMessage && (
                <Alert severity={memberPopupSeverity}>
                  {memberPopupMessage}
                </Alert>
              )}
            </div>
          </div>
          {projectMembers.length > 0 ? (
            projectMembers.map((member, index) => (
              <div key={index} className="member">
                <Avatar alt={member.name} src={member.picture} />
                <div className="member-name-group">
                  <p>{member.name || t('memberSection.unknownMember')}</p>
                  {projectCreator.toString() === member._id && (
                    <p className="creator">({t('memberSection.admin')})</p>
                  )}
                </div>
              </div>
            ))
          ) : (
            <p>{t('memberSection.noMembers')}</p>
          )}
        </div>
      )}
    </div>
  );
};

export default MemberSection;
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { GetStats, ResetStats } from "../../services/user.service";
import LoadingScreen from "../../components/Common/LoadingScreen/LoadingScreen";
import Modal from "@mui/material/Modal";
import CreateProjectFlow from "../../components/CreateProject/CreateProjectFlow";
import ImageUploadModal from "../../components/ImageUploadModal/ImageUploadModal";
import Status from "../../components/Common/Status/Status";
import Project from "../Project/Project";
import CloseConfirmationModal from "../../components/Modals/CloseConfirmationModal";
import {
  Add as AddIcon,
  TrendingUp as TrendingUpIcon,
  AccessTime as AccessTimeIcon,
  PeopleAltOutlined as PeopleAltOutlinedIcon,
} from "@mui/icons-material";
import {
  Button,
  createTheme,
  ThemeProvider,
  styled,
  ListItemButton,
  List,
  Divider,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { GetProjects } from "../../services/project.service";
import "./Dashboard.css";
import { theme, ColorButton, InverseButton } from "../../theme";
import { motion } from "framer-motion";

const Dashboard = ({
  userData,
  accessToken,
  setProjectID,
  setSideBarSelected,
}) => {
  const { t } = useTranslation();
  // auth0
  const { user, isLoading } = useAuth0();
  // notifications
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState(null);
  // data
  const [stats, setStats] = useState(null);
  const [projects, setProjects] = useState([]);
  // modal
  const [openCreateProject, setOpenCreateProject] = useState(false);
  const [openImageUploadModal, setOpenImageUploadModal] = useState(false);
  const [openProject, setOpenProject] = useState(false);
  // state management
  const [loading, setLoading] = useState(false);
  // popups
  const [showCalcExplanationPopUp, setShowCalcExplanationPopUp] =
    useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [sortingMethod, setSortingMethod] = useState("");

  const navigate = useNavigate();

  //----------Styling----------//

  const alternatingColor = ["#FFFFFF", "#F9F9F9"];

  //----------UseEffect Functions----------//

  // get stats data
  useEffect(() => {
    const getStatsData = async () => {
      const { data, error } = await GetStats(
        accessToken,
        user.sub.split("|")[1]
      );
      if (data) {
        setStats(data);
      }
      if (error) {
        setMessageType("error");
        setMessage("Error getting stats");
        console.error(error);
      }
    };

    getStatsData();
    setLoading(false);
  }, [accessToken, user]);

  useEffect(() => {
    setSideBarSelected(0);
  }, [setSideBarSelected]);

  // get projects data
  useEffect(() => {
    const getProjectsData = async () => {
      if (!accessToken || !user) {
        return;
      }
      const { data, error } = await GetProjects(
        accessToken,
        user.sub.split("|")[1]
      );
      if (data) {
        setProjects(data);
      }
      if (error) {
        setMessageType("error");
        setMessage("Error getting projects");
        console.error(error);
      }
    };

    getProjectsData();
    setLoading(false);
  }, [accessToken, user]);

  //----------Helper Functions----------//

  // show notification message
  const showMessage = (data, error, successMsg, errorMsg) => {
    document.getElementById("messageDiv").hidden = false;
    if (data) {
      setMessageType("success");
      setMessage(successMsg);
    }
    if (error) {
      setMessageType("error");
      setMessage(errorMsg);
    }
    setTimeout(() => {
      document.getElementById("messageDiv").hidden = true;
    }, 3000);
  };

  // reset stats
  const HandleResetStats = async () => {
    const userid = user.sub.split("|")[1];
    const formData = new FormData();
    formData.append("userid", userid);

    let { data, error } = await ResetStats(accessToken, userid);
    if (data) {
      window.location.reload();
    }
    if (error) {
      setMessageType("error");
      setMessage("Error getting stats");
      console.error(error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="dashboard-container"
      >
        {loading || isLoading ? (
          <div className="dashboard-loading-screen">
            <LoadingScreen
              header={t("dashboard.loading")}
              text={t("dashboard.loadingText")}
            />
          </div>
        ) : (
          <div className="dashboard-container">
            {openProject ? (
              <Project />
            ) : (
              <div>
                <div className="cards-margin">
                  <div className="dashboard-heading">
                    <div className="dashboard-title-section">
                      <h2 className="dashboard-title">
                        {t("dashboard.pageTitle")}
                      </h2>
                      <p className="dashboard-description">
                        {userData && userData.name
                          ? t("dashboard.welcome", { name: userData.name })
                          : t("dashboard.welcome", { name: user.name })}
                      </p>
                    </div>
                    <div>
                      <ColorButton
                        startIcon={<AddIcon />}
                        variant="contained"
                        onClick={() => setOpenImageUploadModal(true)}
                      >
                        {t("dashboard.uploadImages")}
                      </ColorButton>
                    </div>
                  </div>

                  <div className="cards-grid">
                    {/* Stat boxes */}
                    <div className="card-box">
                      <div className="card-padding">
                        <div className="card-heading">
                          <p>{t("dashboard.imagesProcessed")}</p>
                          <TrendingUpIcon color="primary" />
                        </div>
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ delay: 0.5 }}
                        >
                          <div className="card-content">
                            {stats && stats.images_processed}
                          </div>
                        </motion.div>
                      </div>
                    </div>

                    {/* Stat box 2 */}
                    <div className="card-box">
                      <div className="card-padding">
                        <div className="card-heading">
                          <p>{t("dashboard.timeSaved")} </p>
                          <AccessTimeIcon
                            color="primary"
                            className="time-icon"
                            onMouseEnter={() => {
                              setShowCalcExplanationPopUp(true);
                            }}
                            onMouseLeave={() => {
                              setShowCalcExplanationPopUp(false);
                            }}
                          />
                          <div
                            id="infoPopUp"
                            hidden={!showCalcExplanationPopUp}
                            className="time-popup"
                            style={{
                              top: "100%", // Position the popup below the toggle button
                              left: window.innerWidth <= 1024 ? "75%" : "50%", // Change left position if screen size is small
                              transform: "translateX(-50%)", // Center the popup horizontally
                            }}
                          >
                            <p className="popup-text">
                              {t("dashboard.projectsInitiated")}
                            </p>
                          </div>
                        </div>
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ delay: 0.5 }}
                        >
                          <div className="card-content">
                            {stats && stats.time_saved}
                          </div>
                        </motion.div>
                      </div>
                    </div>

                    {/* Stat box 3 */}
                    <div className="card-box">
                      <div className="card-padding">
                        <div className="card-heading">
                          <p>{t("dashboard.projectsInitiated")}</p>
                          <PeopleAltOutlinedIcon color="primary" />
                        </div>
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ delay: 0.5 }}
                        >
                          <div className="card-content">{projects.length}</div>
                        </motion.div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="table-wrapper">
                  <div className="table-heading">
                    <h1 className="table-title">
                      {t("dashboard.projectsTitle")}
                    </h1>
                    <div className="table-heading-buttons">
                      <div>
                        <InverseButton
                          fullWidth
                          startIcon={<AddIcon />}
                          onClick={() => setOpenCreateProject(true)}
                        >
                          {t("dashboard.createNew")}
                        </InverseButton>
                      </div>
                      <div>
                        <FormControl fullWidth sx={{ m: 1, minWidth: 120 }}>
                          <InputLabel id="sorting-label">
                            {t("dashboard.sortBy")}
                          </InputLabel>
                          <Select
                            labelId="sorting-label"
                            value={sortingMethod}
                            label={t("dashboard.sortBy")}
                            onChange={(e) => {
                              setSortingMethod(e.target.value);
                            }}
                            sx={{ fontSize: "small", color: "#484848" }}
                          >
                            <MenuItem
                              sx={{ fontSize: "small", color: "#484848" }}
                              value={"Status"}
                            >
                              {t("dashboard.status")}
                            </MenuItem>
                            <MenuItem
                              sx={{ fontSize: "small", color: "#484848" }}
                              value={"Title"}
                            >
                              {t("dashboard.title")}
                            </MenuItem>
                            <MenuItem
                              sx={{ fontSize: "small", color: "#484848" }}
                              value={"Date"}
                            >
                              {t("dashboard.dateStarted")}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  <Divider />
                  <div className="">
                    <List sx={{ padding: 0 }}>
                      {projects.length > 0 ? (
                        projects.map((project, index) => (
                          <div>
                            <div>
                              <ListItemButton
                                sx={{
                                  backgroundColor: alternatingColor[index % 2],
                                  justifyContent: "space-between",
                                }}
                                onClick={() => {
                                  setProjectID(project._id);
                                  navigate("/project/" + project._id);
                                  setSideBarSelected(null);
                                }}
                              >
                                <div className="display-block">
                                  <h1 className="table-row-title">
                                    {project.project_name}
                                  </h1>
                                  <p className="table-row-desc">
                                    {project.project_description}
                                  </p>
                                </div>
                                {project.project_trials.length > 0 ? (
                                  <div>
                                    <Status
                                      progress={t("dashboard.inProgress")}
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <Status
                                      progress={t("dashboard.notStarted")}
                                    />
                                  </div>
                                )}
                              </ListItemButton>
                            </div>
                            <Divider />
                          </div>
                        ))
                      ) : (
                        <div className="no-project">
                          <h2>{t("dashboard.noProjects")}</h2>
                          <p className="no-project-desc">
                            {t("dashboard.noProjectsDescription")}
                          </p>
                        </div>
                      )}
                    </List>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        <Modal
          open={openCreateProject}
          onClose={() => setOpenCreateProject(false)}
        >
          <CreateProjectFlow
            setOpenCreateProject={setOpenCreateProject}
            accessToken={accessToken}
            userID={user.sub.split("|")[1]}
            createTrialAfterProject={true}
          />
        </Modal>

        <CloseConfirmationModal
          openConfirmationModal={openConfirmationModal}
          setOpenConfirmationModal={setOpenConfirmationModal}
          setOpenImageUploadModal={setOpenImageUploadModal}
        />

        <Modal
          open={openImageUploadModal}
          onClose={() => setOpenConfirmationModal(true)}
        >
          <ImageUploadModal
            setOpenImageModal={setOpenImageUploadModal}
            setOpenConfirmationModal={setOpenConfirmationModal}
            accessToken={accessToken}
            userID={user.sub.split("|")[1]}
            nameOfUser={user.name}
          />
        </Modal>
      </motion.div>
    </ThemeProvider>
  );
};

export default Dashboard;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
// style
import "./products.css";

const Products = () => {
  const { t } = useTranslation();
  const productsData = [
    {
      id: "petri-dish-analysis",
      icon: "../../../../assets/images/Products/petri.png",
      image: "../../../../assets/images/Products/petri.png",
      titleKey: "products-home.microbiology.title",
      textKey: "products-home.microbiology.text",
      includedKey: "products-home.microbiology.included",
    },
    {
      id: "microscopy",
      icon: "../../../assets/images/Products/microscopy.png",
      image: "../../../assets/images/Products/microscopy.png",
      titleKey: "products-home.hematology.title",
      textKey: "products-home.hematology.text",
      includedKey: "products-home.hematology.included",
    },
    {
      id: "egel",
      icon: "../../../assets/images/Products/egel.png",
      image: "../../../assets/images/Products/egel.png",
      titleKey: "products-home.drugDevelopment.title",
      textKey: "products-home.drugDevelopment.text",
      includedKey: "products-home.drugDevelopment.included",
    },
  ];

  const [selectedProduct, setSelectedProduct] = useState(productsData[0]);

  // Handler for when an icon is clicked
  const handleIconClick = (productId) => {
    const product = productsData.find((p) => p.id === productId);
    setSelectedProduct(product);
  };

  return (
    <div className="product-section-container">
      <p className="products-title">{t("products-home.ourProducts")}</p>
      <div className="product-selection-area">
        <div className="product-icons">
          <div
            onClick={() => handleIconClick("petri-dish-analysis")}
            className={`product-icon-container ${
              selectedProduct.id === "petri-dish-analysis" ? "selected" : ""
            }`}
          >
            <img
              key="petri-dish-analysis"
              src={require("../../../assets/images/Products/petri-icon.png")}
              alt={t("products-home.microbiology.title")}
              className="product-icon"
            />
            <div className="product-icon-title"> {t("products-home.microbiology.title")}</div>
          </div>
          <div
            onClick={() => handleIconClick("microscopy")}
            className={`product-icon-container ${
              selectedProduct.id === "microscopy" ? "selected" : ""
            }`}
          >
            <img
              key="microscopy"
              src={require("../../../assets/images/Products/microscopy-icon.png")}
              alt={t("products-home.hematology.title")}
              className="product-icon"
            />
            <div className="product-icon-title">
              {t("products-home.hematology.title")}
            </div>
          </div>
          <div
            onClick={() => handleIconClick("egel")}
            className={`product-icon-container ${
              selectedProduct.id === "egel" ? "selected" : ""
            }`}
          >
            <img
              key="egel"
              src={require("../../../assets/images/Products/egel-icon.png")}
              alt={t("products-home.drugDevelopment.title")}
              className="product-icon"
            />
            <div className="product-icon-title">
              {t("products-home.drugDevelopment.title")}
            </div>
          </div>
        </div>
        <div className="product-image">
          {selectedProduct.id === "petri-dish-analysis" && (
            <img
              src={require("../../../assets/images/Products/petri-prod.png")}
              alt={t("products-home.microbiology.title")}
            />
          )}
          {selectedProduct.id === "microscopy" && (
            <img
              src={require("../../../assets/images/Products/microscopy-prod.png")}
              alt={t("products-home.hematology.title")}
            />
          )}
          {selectedProduct.id === "egel" && (
            <img
              src={require("../../../assets/images/Products/egel-prod.png")}
              alt={t("products-home.drugDevelopment.title")}
            />
          )}
        </div>
        <div className="product-details">
          <h2 className="product-details-title">
            {t(selectedProduct.titleKey)}
          </h2>
          <p className="product-details-text">{t(selectedProduct.textKey)}</p>
          <div>
            <ul>
              {t(selectedProduct.includedKey, { returnObjects: true }).map(
                (item, index) => (
                  <li className="bullet-points" key={index}>
                    {item}
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, TextField, FormGroup, FormControlLabel } from '@mui/material';
import Alert from '@mui/material/Alert';
import { ColorButton, theme } from '../../../theme';
import { EditProject, DeleteProject } from '../../../services/project.service';
import { useNavigate } from 'react-router-dom';
import DeleteProjectModal from '../DeleteProjectModal/DeleteProjectModal';
import "./EditProjectModal.css";

const EditProjectModal = ({
    openEditProjectModal,
    setOpenEditProjectModal,
    projectID,
    accessToken,
    projectTitle,
    projectDescription,
    projectNotes,
    projectColor,
    setProjectDeleted
}) => {
    const { t } = useTranslation();
    const [severityProject, setSeverityProject] = useState('');
    const [messageProject, setMessageProject] = useState('');

    const [editedColor, setEditedColor] = useState(projectColor || null);
    const [editedTitle, setEditedTitle] = useState(projectTitle || null);
    const [editedDescription, setEditedDescription] = useState(projectDescription || null);
    const [editedNotes, setEditedNotes] = useState(projectNotes || null);
    const [openDeleteProjectModal, setOpenDeleteProjectModal] = useState(false);

    const navigate = useNavigate();
    
    useEffect(() => {
        if (openEditProjectModal) {
            setEditedTitle(projectTitle);
            setEditedDescription(projectDescription);
            setEditedNotes(projectNotes);
            setEditedColor(projectColor);
        }
    }, [openEditProjectModal, projectTitle, projectDescription, projectNotes, projectColor]);

    const closeProjectEditModal = () => {
        setOpenEditProjectModal(false);
        setEditedTitle(projectTitle);
        setEditedDescription(projectDescription);
        setEditedNotes(projectNotes);
        setEditedColor(projectColor);

        setSeverityProject('');
        setMessageProject('');
    };

    const handleColorChange = (color) => {
        const colors = document.getElementsByClassName('colors-formgroup')[0].children;
        for (let i = 0; i < colors.length; i++) {
            colors[i].style.border = 'none';
        }
        setEditedColor(color);
    };

    const deleteProject = () => {
        let formData = new FormData();
        formData.append("project_id", projectID);
        DeleteProject(accessToken, formData).then((response) => {
            setProjectDeleted(true);
            navigate('/history');
        });
    };

    const editProject = () => {
        let formData = new FormData();

        if (!editedTitle) {
            setSeverityProject('error');
            setMessageProject(t('editProjectModal.errors.noTitle'));
            setTimeout(() => {
                setSeverityProject('');
                setMessageProject('');
            }, 3000);
            return;
        }

        if (!editedColor) {
            setSeverityProject('error');
            setMessageProject(t('editProjectModal.errors.noColor'));
            setTimeout(() => {
                setSeverityProject('');
                setMessageProject('');
            }, 3000);
            return;
        }

        if (editedTitle === projectTitle && editedDescription === projectDescription && editedNotes === projectNotes && editedColor === projectColor) {
            setSeverityProject('error');
            setMessageProject(t('editProjectModal.errors.noChanges'));
            setTimeout(() => {
                setSeverityProject('');
                setMessageProject('');
            }, 3000);
            return;
        }

        formData.append("project_id", projectID);
        formData.append("project_name", editedTitle);
        formData.append("project_description", editedDescription);
        formData.append("project_notes", editedNotes);
        formData.append("project_color", editedColor);

        EditProject(accessToken, formData).then((response) => {
            window.location.reload();
        });
    };

    return (
        <Modal
            open={openEditProjectModal}
            onClose={() => closeProjectEditModal()}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            <div className="edit-project-modal">
                <div className="modal-header">
                    <h2 className="modal-title">{t('editProjectModal.title')}</h2>
                    <div className="modal-close" onClick={() => closeProjectEditModal()}>
                        {t('common.close')}
                    </div>
                </div>
                <div className="edit-project-modal-wrapper">
                    <div className="edit-project-modal-column">
                        <TextField
                            label={t('editProjectModal.projectTitle')}
                            variant="outlined"
                            value={editedTitle}
                            onChange={(e) => setEditedTitle(e.target.value)}
                            fullWidth
                        />
                        <div className="project-color-section">
                            <h2 className="color-select-title">{t('editProjectModal.selectColor')}</h2>
                            <FormGroup required className="colors-formgroup" style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                {['#FC5A5A', '#FFA043', '#7FF6BC', '#83B4FF', '#D3BCFC', '#FDAEFF', '#84818A'].map((color) => (
                                    <FormControlLabel
                                        key={color}
                                        value={editedColor}
                                        slotProps={{ textField: { required: true } }}
                                        onClick={() => handleColorChange(color)}
                                        control={
                                            <div
                                                style={{
                                                    backgroundColor: color,
                                                    width: '30px',
                                                    height: '30px',
                                                    borderRadius: '50%',
                                                    border: editedColor === color ? '2px solid #23C9E8' : null
                                                }}
                                            />
                                        }
                                    />
                                ))}
                            </FormGroup>
                        </div>
                        <TextField
                            label={t('editProjectModal.projectDescription')}
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            value={editedDescription}
                            onChange={(e) => setEditedDescription(e.target.value)}
                        />

                        <TextField
                            label={t('editProjectModal.projectNotes')}
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            value={editedNotes}
                            onChange={(e) => setEditedNotes(e.target.value)}
                        />
                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                            <ColorButton onClick={() => editProject()} className='edit-project-button' style={{ backgroundColor: theme.palette.primary.main }}> 
                                {t('editProjectModal.saveChanges')} 
                            </ColorButton>
                            <ColorButton onClick={() => setOpenDeleteProjectModal(true)} className='edit-project-button' style={{ width: '50%', marginTop: '6px', backgroundColor: theme.palette.secondary.main }}> 
                                {t('editProjectModal.deleteProject')} 
                            </ColorButton>
                            {severityProject && messageProject && <Alert style={{ width: '50%', marginTop: '6px' }} severity={severityProject}>{messageProject}</Alert>}
                        </div>
                    </div>
                </div>
                <DeleteProjectModal
                    openDeleteProjectModal={openDeleteProjectModal}
                    setOpenDeleteProjectModal={setOpenDeleteProjectModal}
                    projectID={projectID}
                    projectTitle={projectTitle}
                    accessToken={accessToken}
                    setProjectDeleted={setProjectDeleted}
                />
            </div>
        </Modal>
    );
};

export default EditProjectModal;
import React from 'react';
import { cls } from '../../../utils/utils';

const classes = {
  base: 'bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative',
  header: 'font-bold',
  content: 'block sm:inline',
};

// type: success | error
const Message = ({ type, children }) => {

  const typeClass = {
    success: 'bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative',
    error: 'bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative',
  };


  return (
    <div className={cls(`${typeClass[type]}`)} role="alert">
      <div className={classes.header}>{type}</div>
      <span className={classes.content}>{children}</span>
      
    </div>
  );
}

export default Message;
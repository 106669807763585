import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Collapse } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./Founders.css";

function Founders() {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const toggleCollapsible = () => {
    setIsOpen(!isOpen);
  };

  const founders = [
    { name: "Ali Mehdi", role: "ceo", image: "Ali.jpg" },
    { name: "Abhinav Batra", role: "cto", image: "Abhinav.jpg" },
    { name: "Felix Lambert", role: "R&DAdvisor", image: "felix.jpg" },
    {
      name: "Valentina Goanta",
      role: "businessAdvisor",
      image: "Valentina.jpg",
    },
    { name: "Michelle Goanta", role: "businessAdvisor", image: "michelle.jpg" },
  ];

  return (
    <div className="people-container">
      <div className="collapsible-title" onClick={toggleCollapsible}>
        <KeyboardArrowDownIcon fontSize="large" />
        {t("founders.title")}
      </div>
      <Collapse in={isOpen}>
        <div className="users-container">
          {founders.map((founder, index) => (
            <div key={index} className="user-description">
              <img
                src={require(`../../../assets/images/About/${founder.image}`)}
                alt={founder.name}
                className="individual-img"
              />
              <p className="name">{founder.name}</p>
              <p className="bio-description">
                {t(`founders.role.${founder.role}`)}
              </p>
            </div>
          ))}
        </div>
      </Collapse>
    </div>
  );
}

export default Founders;

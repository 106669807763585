import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Collapse } from '@mui/material';
import { useTranslation } from "react-i18next";
import "./Advisors.css"

function Advisors() {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();

    const toggleCollapsible = () => {
        setIsOpen(!isOpen);
    };

    const advisors = [
        { name: "Margaret Bywater-Ekegärd", role: t("advisors.advisor1.role"), company: t("advisors.advisor1.company") },
        { name: "Alex Benjamin", role: t("advisors.advisor2.role"), company: t("advisors.advisor2.company") },
        { name: "Donald Mclaren", role: t("advisors.advisor3.role"), company: t("advisors.advisor3.company") },
        { name: "Benjamin Scott", role: t("advisors.advisor4.role"), company: t("advisors.advisor4.company") },
    ];

    return (
        <div className="people-container">
            <div
                className="collapsible-title"
                onClick={toggleCollapsible}
            >
                <KeyboardArrowDownIcon fontSize='large' />
                {t("advisors.title")}
            </div>
            <Collapse in={isOpen}>
                <div className="users-container">
                    {advisors.map((advisor, index) => (
                        <div key={index} className="user-description">
                            <img
                                src={require(`../../../assets/images/Advisors/${advisor.name.split(' ')[0].toLowerCase()}.jpeg`)}
                                alt={advisor.name}
                                className="individual-img"
                            />
                            <p className='name'>{advisor.name}</p>
                            <p className="bio-description">{advisor.role}</p>
                            {advisor.company && <p className="bio-description">{advisor.company}</p>}
                        </div>
                    ))}
                </div>
            </Collapse>
        </div>
    );
}

export default Advisors;
import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Collapse } from '@mui/material';
import { useTranslation } from "react-i18next";
import "./Team.css"

function Team() {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();

    const toggleCollapsible = () => {
        setIsOpen(!isOpen);
    };

    const teamMembers = [
        { name: "Nidhi Jampana", role: "uiuxDesigner" },
        { name: "Mika Kaeja", role: "researchAssociate" },
        { name: "Sarah Varughese", role: "researchAssociate" },
        { name: "Ashenafee Mandefro", role: "fullStackDeveloper" },
        { name: "Hamza Abu-Zaid", role: "researchAssociate" }
    ];

    return (
        <div className="people-container">
            <div
                className="collapsible-title"
                onClick={toggleCollapsible}
            >
                <KeyboardArrowDownIcon fontSize='large' />
                {t("team.title")}
            </div>
            <Collapse in={isOpen}>
                <div className="users-container">
                    {teamMembers.map((member, index) => (
                        <div key={index} className="user-description">
                            <img
                                src={require(`../../../assets/images/About/${member.name.split(' ')[0].toLowerCase()}.jpg`)}
                                alt={member.name}
                                className="individual-img"
                            />
                            <p className='name'>{member.name}</p>
                            <p className="bio-description">{t(`team.role.${member.role}`)}</p>
                        </div>
                    ))}
                </div>
            </Collapse>
        </div>
    );
}

export default Team;
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { useTranslation } from "react-i18next";

const Tutorial = ({ open, onClose }) => {
    const { t } = useTranslation();
    const steps = t('eGel-editor.tutorial.steps', { returnObjects: true });

    const stepBoxStyle = {
        marginBottom: 8,
        p: 2,
        border: "1px solid #ddd",
        borderRadius: 4,
        backgroundColor: "#f9f9f9",
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle style={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold' }}
            >{t('eGel-editor.tutorial.title')}</DialogTitle>
            <DialogContent>
                {steps.map((step, index) => (
                    <div key={index} style={stepBoxStyle}>
                        <h3 style={{ marginBottom: 8, fontSize: '1.2rem', fontWeight: 'bold' }}
                        >{step.title}</h3>
                        <p>{step.description}</p>
                    </div>
                ))}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {t('eGel-editor.tutorial.gotItButton')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default Tutorial;

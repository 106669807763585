import React from "react";
import BBoxEditor from "../BBoxEditor/BBoxEditor";
import GelElectrophoresisEditor from "../GelElectrophoresisEditor/GelElectrophoresisEditor";

const ImageEditor = (props) => {
    const { imageData } = props;

    // Determine the type of image and render the appropriate editor
    const renderEditor = () => {
        if (imageData.assay_type === "Gel Electrophoresis") {
            return <GelElectrophoresisEditor {...props} />;
        } else {
            return <BBoxEditor {...props} />;
        }
    };

    return renderEditor();
};

export default ImageEditor;

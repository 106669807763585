import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import Modal from '@mui/material/Modal';
import { ColorButton } from "../../../theme";
import MemberSection from '../MemberSection/MemberSection';
import CloseConfirmationModal from '../../../components/Modals/CloseConfirmationModal';
import ImageUploadModal from '../../../components/ImageUploadModal/ImageUploadModal';
import EditProjectModal from '../EditProject/EditProjectModal';
import "./PageHeader.css";

const PageHeader = ({ projectTitle, projectDescription, projectNotes, projectColor, projectMembers, accessToken, projectID, user, setProjectDeleted, projectCreator }) => {
    const { t } = useTranslation();
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [openImageUploadModal, setOpenImageUploadModal] = useState(false);
    const [openEditProjectModal, setOpenEditProjectModal] = useState(false);

    return (
        <div className="project-page">
            <div className="project-page-row">
                <div>
                    <h1 className="project-title">
                        {projectTitle}
                        {user.sub.split("|")[1] === projectCreator &&
                            <EditSharpIcon className="edit-icon" onClick={() => setOpenEditProjectModal(true)} />
                        }
                        <MemberSection projectMembers={projectMembers} accessToken={accessToken} projectID={projectID} projectName={projectTitle} userID={user.sub.split("|")[1]} projectCreator={projectCreator} />
                    </h1>
                </div>
                <ColorButton onClick={() => setOpenImageUploadModal(true)} className="upload-image-button">
                    {t('pageHeader.uploadImages')}
                </ColorButton>
            </div>
            <div className="project-page-row">
                <div className="project-description">
                    <p className="project-description-title">{t('pageHeader.description')}</p>
                    <p className="project-description-text">
                        {projectDescription ? projectDescription : t('pageHeader.noDescription')}
                    </p>
                </div>
                <div className="project-notes">
                    <p className="project-notes-title">{t('pageHeader.notes')}</p>
                    <p className="project-notes-text">
                        {projectNotes ? projectNotes : t('pageHeader.noNotes')}
                    </p>
                </div>
            </div>

            <Modal
                open={openImageUploadModal}
                onClose={() => setOpenConfirmationModal(true)}
            >
                <ImageUploadModal
                    setOpenImageModal={setOpenImageUploadModal}
                    accessToken={accessToken}
                    userID={user.sub.split("|")[1]}
                    nameOfUser={user.name}
                    projectID={projectID}
                    setOpenConfirmationModal={setOpenConfirmationModal}
                />
            </Modal>

            <CloseConfirmationModal
                openConfirmationModal={openConfirmationModal}
                setOpenConfirmationModal={setOpenConfirmationModal}
                setOpenImageUploadModal={setOpenImageUploadModal}
            />

            <EditProjectModal
                openEditProjectModal={openEditProjectModal}
                setOpenEditProjectModal={setOpenEditProjectModal}
                accessToken={accessToken}
                projectID={projectID}
                projectTitle={projectTitle}
                projectDescription={projectDescription}
                projectNotes={projectNotes}
                projectColor={projectColor}
                setProjectDeleted={setProjectDeleted}
            />
        </div>
    );
}

export default PageHeader;
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, TextField, Button } from '@mui/material';
import Alert from '@mui/material/Alert';
import { DeleteProject } from '../../../services/project.service';
import { useNavigate } from 'react-router-dom';
import "./DeleteProjectModal.css";
import { ColorButton, InverseButton, theme } from '../../../theme';
import { ThemeProvider } from '@mui/material/styles';

const DeleteProjectModal = ({
    openDeleteProjectModal,
    setOpenDeleteProjectModal,
    projectID,
    projectTitle,
    accessToken,
    setProjectDeleted
}) => {
    const { t } = useTranslation();
    const [severity, setSeverity] = useState('');
    const [message, setMessage] = useState('');
    const [projectName, setProjectName] = useState('');

    const navigate = useNavigate();

    const closeProjectDeleteModal = () => {
        setOpenDeleteProjectModal(false);
        setSeverity('');
        setMessage('');
    };

    const deleteProject = () => {
        let formData = new FormData();
        formData.append("project_id", projectID);
        DeleteProject(accessToken, formData).then(() => {
            setProjectDeleted(true);
            setOpenDeleteProjectModal(false);
            navigate('/history');
        });
    }

    const handleCorrectProjectName = () => {
        if (projectName === projectTitle) {
            deleteProject();
        } else {
            setSeverity('error');
            setMessage(t('deleteModal.errorProjectNameMismatch'));
        }
    }

    return (
        <ThemeProvider theme={theme}>
        <Modal
            open={openDeleteProjectModal}
            onClose={closeProjectDeleteModal}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            <div className="delete-project-modal">
                <div className="delete-project-modal-content">
                    <h2 className='delete-project-header'>{t('deleteModal.title')}</h2>
                    <p className='delete-project-confirmation-1'>
                        {t('deleteModal.confirmationMessage', { projectTitle })}
                    </p>
                    <p className='delete-project-confirmation-2'>
                        {t('deleteModal.typeToConfirm', { projectTitle })}
                    </p>
                    <div className="delete-project-buttons">
                        <div className='project-name-input'>
                            <TextField
                                id="project-name"
                                variant="outlined"
                                onChange={(e) => setProjectName(e.target.value)}
                            />
                        </div>
                        <div className='buttons-container'>
                            <ColorButton 
                                onClick={handleCorrectProjectName} 
                                variant="contained"
                            >
                                {t('common.delete')}
                            </ColorButton>
                            <InverseButton 
                                onClick={closeProjectDeleteModal}
                            >
                                {t('common.cancel')}
                            </InverseButton>
                        </div>
                    </div>
                    <div className='delete-project-alert'>
                       {severity && <Alert severity={severity}>{message}</Alert>}
                    </div>
                </div>
            </div>
        </Modal>
        </ThemeProvider>
    );
};

export default DeleteProjectModal;
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./assets/translations/en.json";
import translationFR from "./assets/translations/fr.json";
import translationJA from "./assets/translations/ja.json";

// the translations
const resources = {
    en: {
        translation: translationEN
    },
    fr: {
        translation: translationFR
    }, 
    ja: {
        translation: translationJA
    }
};

i18n.use(initReactI18next).init({
    resources, // the translations
    lng: "en", // default language
    fallbackLng: "fr",
    interpolation: {
        escapeValue: false // react already safes from xss
    }
});

export default i18n;
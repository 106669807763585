import React, { useState, useCallback } from "react";
import {
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Box,
  Typography,
  IconButton,
  useMediaQuery,
  Paper,
  TableContainer,
  CircularProgress,
  LinearProgress
} from "@mui/material";
import { useTranslation } from "react-i18next";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import "./FileUploadComponent.css";
import { decode, decodeImage, toRGBA8 } from "utif";

const FileUploadComponent = ({
  setMessage,
  setSeverity,
  setFilesReady,
  fileList,
  setFileList,
  setFilenames,
  fileNames,
  rotateImage
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [dragActive, setDragActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDrag = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  }, []);

  const splitFilename = useCallback((filename) => {
    const lastDotIndex = filename.lastIndexOf('.');
    const name = filename.slice(0, lastDotIndex);
    const extension = filename.slice(lastDotIndex);
    return { name, extension };
  }, []);

  const changeFileName = useCallback((event, index) => {
    const newFileNames = [...fileNames];
    const { extension } = splitFilename(newFileNames[index]);
    newFileNames[index] = event.target.value + extension;
    setFilenames(newFileNames);
  }, [fileNames, setFilenames, splitFilename]);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFileChange(e);
    }
  }, []);

  const handleError = useCallback((message) => {
    setMessage(message);
    setSeverity("error");
    setTimeout(() => {
      setSeverity("");
      setMessage("");
    }, 3000);
    console.error(message);
  }, [setMessage, setSeverity]);

  const handleFileChange = useCallback((event) => {
    setFilesReady(false);
    setIsLoading(true);
    setProgress(0);
    const files = event.target.files || event.dataTransfer.files;
  
    if (!files.length) {
      setFilesReady(false);
      setIsLoading(false);
      return;
    }
  
    if (files.length > 100) {
      handleError(t("fileUpload.maxFiles"));
      setIsLoading(false);
      return;
    }
  
    if (areAllFilesValid(files)) {
      processFiles(files);
    } else {
      setIsLoading(false);
    }
  }, [setFilesReady, handleError, t]);

  const areAllFilesValid = useCallback((files) => {
    return Array.from(files).every((file) => {
      if (!file.type.match("image.*") && file.type !== "image/tiff") {
        handleError(t("fileUpload.onlyImages"));
        return false;
      }
      if (file.type !== "image/jpeg" && file.type !== "image/png" && file.type !== "image/tiff") {
        handleError(t("fileUpload.jpgPngTiff"));
        return false;
      }
      if (file.size > 5000000) {
        handleError(
          t("fileUpload.maxFileSize", {
            fileName: file.name,
            fileSize: Math.ceil(file.size / 1000000)
          })
        );
        return false;
      }
      return true;
    });
  }, [handleError, t]);
  
  const processFiles = useCallback(async (files) => {
    const totalFiles = files.length;
    let processedFiles = 0;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.type === "image/tiff") {
        try {
          await processTiffFile(file);
        } catch (error) {
          handleError(`Failed to process TIFF file: ${file.name}. ${error.message}`);
        }
      } else {
        const image = new Image();
        image.src = URL.createObjectURL(file);
        image.onload = () => processImage(file, image);
      }
      processedFiles++;
      setProgress((processedFiles / totalFiles) * 100);
    }

    setFilesReady(true);
    setIsLoading(false);
  }, [handleError, setFilesReady]);

  const processTiffFile = useCallback(async (file) => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const tiffData = new Uint8Array(arrayBuffer);
      const ifds = decode(tiffData);
      decodeImage(tiffData, ifds[0])
      
      if (ifds.length === 0) {
        throw new Error("No images found in TIFF file");
      }
  
      const firstImage = ifds[0];
      if (!firstImage.width || !firstImage.height) {
        throw new Error("Invalid image dimensions");
      }
  
      const rgba = toRGBA8(firstImage);
  
      const canvas = document.createElement("canvas");
      canvas.width = firstImage.width;
      canvas.height = firstImage.height;
      const ctx = canvas.getContext("2d");
  
      const imageData = new ImageData(
        new Uint8ClampedArray(rgba),
        firstImage.width,
        firstImage.height
      );
  
      ctx.putImageData(imageData, 0, 0);
  
      canvas.toBlob((blob) => {
        const newFile = new File([blob], file.name, {
          type: "image/png",
          lastModified: Date.now(),
        });
  
        setFileList((prev) => [...prev, newFile]);
        setFilenames((prev) => [...prev, file.name]);
      }, "image/png");
    } catch (error) {
      console.error("Error processing TIFF file:", error);
      handleError(t("fileUpload.tiffProcessError", { fileName: file.name, errorMessage: error.message }));
    }
  }, [handleError, setFileList, setFilenames, t]);
  
  const processImage = useCallback((file, image) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
  
   
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0);
    
  
    canvas.toBlob((blob) => {
      const newFile = new File([blob], file.name, {
        type: file.type,
        lastModified: Date.now(),
      });
  
      setFileList((prev) => [...prev, newFile]);
      setFilenames((prev) => [...prev, file.name]);
    }, file.type);
  }, [setFileList, setFilenames]);

  const removeFile = useCallback((index) => {
    setFilesReady(false);
    const newFileList = [...fileList];
    newFileList.splice(index, 1);
    setFileList(newFileList);

    const newFileNames = [...fileNames];
    newFileNames.splice(index, 1);
    setFilenames(newFileNames);

    setFilesReady(true);
  }, [fileList, fileNames, setFileList, setFilenames, setFilesReady]);

  return (
    <Box className="file-upload-wrapper">
      {fileList.length === 0 ? (
        <Box
          className={`image-upload-container ${dragActive ? 'drag-active' : ''}`}
          onDragEnter={handleDrag}
        >
          {dragActive && (
            <Box
              className="drag-overlay"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            />
          )}

          <CloudUploadIcon className="upload-icon" />
          <Typography variant="h6" className="upload-text">{t("fileUpload.dragDrop")}</Typography>
          <Typography variant="body1" className="upload-text">{t("fileUpload.dragDrop2")}</Typography>
          <Typography variant="body2" className="upload-text">{t("fileUpload.or")}</Typography>

          <label htmlFor="fileInput" className="upload-button-wrapper">
            <input
              id="fileInput"
              type="file"
              accept=".jpg, image/jpeg, image/png, image/tiff"
              multiple
              onChange={handleFileChange}
              className="hidden-input"
            />
            <span className="upload-button">
              {isLoading ? <CircularProgress size={24} color="inherit" /> : t("fileUpload.uploadButton")}
            </span>
          </label>

          {isLoading && (
            <Box className="progress-wrapper">
              <LinearProgress variant="determinate" value={progress} className="progress-bar" />
              <Typography variant="body2" className="progress-text">
                {t("fileUpload.processing")} {Math.round(progress)}%
              </Typography>
            </Box>
          )}
        </Box>
      ) : (
        <Paper className="file-list-wrapper">
          <TableContainer>
            <Table size={isMobile ? "small" : "medium"} className="file-table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">{t("fileUpload.tableHeader.number")}</TableCell>
                  <TableCell>{t("fileUpload.tableHeader.thumbnail")}</TableCell>
                  <TableCell>{t("fileUpload.tableHeader.fileName")}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fileList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((file, index) => {
                    const { name } = splitFilename(fileNames[page * rowsPerPage + index]);
                    return (
                      <TableRow key={index}>
                        <TableCell align="center" className="file-number">
                          {page * rowsPerPage + index + 1}
                        </TableCell>
                        <TableCell>
                          <img
                            src={URL.createObjectURL(file)}
                            alt="thumbnail"
                            className="file-thumbnail"
                          />
                        </TableCell>
                        <TableCell className="file-name-cell">
                          <TextField
                            fullWidth
                            value={name}
                            onChange={(event) =>
                              changeFileName(event, page * rowsPerPage + index)
                            }
                            size={isMobile ? "small" : "medium"}
                            className="file-name-input"
                          />
                        </TableCell>
                        <TableCell align="right">
                          <IconButton onClick={() => removeFile(page * rowsPerPage + index)} className="delete-button">
                            <DeleteRoundedIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}

                {page * rowsPerPage + rowsPerPage - 1 >= fileList.length && (
                  <TableRow>
                    <TableCell colSpan={4} className="upload-more-cell">
                      <label htmlFor="additionalFileInput" className="upload-more-label">
                        <input
                          id="additionalFileInput"
                          type="file"
                          accept=".jpg, image/jpeg, image/png, image/tiff"
                          multiple
                          onChange={handleFileChange}
                          className="hidden-input"
                        />
                        <span className="upload-more-button">
                          {isLoading ? <CircularProgress size={24} color="inherit" /> : t("fileUpload.uploadMore")}
                        </span>
                      </label>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={fileList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[4, 8, 16]}
            className="table-pagination"
          />
        </Paper>
      )}
    </Box>
  );
};

export default FileUploadComponent;
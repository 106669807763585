// react
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// MUI
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import InboxIcon from "@mui/icons-material/Inbox";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DeleteIcon from "@mui/icons-material/Delete";
import Modal from "@mui/material/Modal";
import DeleteProjectModal from "../../pages/Project/DeleteProjectModal/DeleteProjectModal";
// components
import CreateProjectFlow from "../CreateProject/CreateProjectFlow";
// auth0
import { useAuth0 } from "@auth0/auth0-react";
// services
import { GetProjects, GetNotifications, SendInviteResponse } from "../../services/project.service";
// styling
import "./dashboard-sidebar.css";

export default function DashboardSidebar({
    token,
    user,
    sideBarSelected,
    setSideBarSelected,
    InfoUpdated,
    userData,
    projectDeleted
}) {
    const [projectsDropDownOpen, setProjectsDropDownOpen] = useState(true)
    const [notifsDropDownOpen, setNotifsDropDownOpen] = useState(false)
    const [openCreateProject, setOpenCreateProject] = useState(false);
    const [openCreateTrial, setOpenCreateTrial] = useState(false);
    const [projects, setProjects] = useState(null);
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth0();
    const { t } = useTranslation();
    const [valuesFetched, setValuesFetched] = useState(false);
    const [openDeleteProjectModal, setOpenDeleteProjectModal] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const [notifications, setNotifications] = useState([]);


    const sidebarItems = [
        { text: t("sidebar.dashboard"), icon: <SpaceDashboardIcon /> },
        { text: t("sidebar.projects"), icon: <LocalLibraryIcon /> },
        { text: t("sidebar.notifications"), icon: <InboxIcon /> }
    ];

    const handleSidebarClick = (index) => {
        switch (index) {
            case 0:
                navigate("/history");
                setSideBarSelected(0);
                break;
            case 1:
                toggleDropdown(index);
                break;
            case 2:
                toggleDropdown(index);
                break;
            default:
                break;
        }
    };

    const toggleDropdown = (index) => {
        switch (index) {
            case 1:
                setProjectsDropDownOpen(!projectsDropDownOpen);
                break;
            case 2:
                setNotifsDropDownOpen(!notifsDropDownOpen);
                break;
            default:
                break;
        }
    };

    const openProject = (projectID) => {
        navigate(`/project/${projectID}`);
        setSideBarSelected(null);
    };

    const onCloseCreateProjectModal = () => {
        setOpenCreateProject(false);
        // show success message for 3 seconds
        setTimeout(() => {
            setOpenCreateProject(false);
        }, 3000);
        // show create trial modal
        setOpenCreateTrial(true);
    };

    const renderDropdownButton = (text, index) => {
        const isOpen = (text === "Projects" ? projectsDropDownOpen : notifsDropDownOpen);

        if (text === "Projects" || text === "Notifications") {
            return (
                <button onClick={() => toggleDropdown(index)}>
                    {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </button>
            );
        }
        return null;
    };

    useEffect(() => {
        if (token && user && !valuesFetched) {
            setValuesFetched(true);
        }
    }, [token, user]);

    // get notifications
    useEffect(() => {
        if (token != null && userData.email != null) {
            GetNotifications(token, userData.email).then((response) => {
                // for each notification, display a notification
                let notifications = [];
                if (response.data && response.data.length !== 0) {
                    response.data.forEach((notification) => {
                        let notificationMessage = "";
                        switch (notification.notification_type) {
                            case "invite":
                                notificationMessage = `You have been invited to join the project ${notification.project_name}.`;
                                notifications.push({ "project_id": notification.project_id, "project_name": notification.project_name, "message": notificationMessage, "notification_type": "invite" });
                                break;
                            default:
                                break;
                        }
                    }
                    );
                    setNotifications(notifications);
                    setNotifsDropDownOpen(true);
                }

            });
        }
    }, [token, userData.email]);

    // accept/decline invite
    const handleInvite = (answerToInvite, projectID) => {
        // send answer to invite
        // if accepted, add user to project
        // if declined, remove notification
        let formData = new FormData();
        formData.append("email", userData.email);
        formData.append("project_id", projectID);
        formData.append("user_id", user);
        formData.append("response", answerToInvite);
        SendInviteResponse(token, formData).then((response) => {
            if (response.error || response.data.error) {
                console.error("Error responding to invite: ", response.error);
            } else {
                if (answerToInvite === true) {
                    // update the project list
                    fetchProjects();
                    openProject(projectID);
                    // remove the notification
                    setNotifications((prevNotifications) => {
                        return prevNotifications.filter((notification) => notification.project_id !== projectID);
                    });
                } else {
                    // replace the notification with the accepted/declined notification
                    setNotifications((prevNotifications) => {
                        return prevNotifications.filter((notification) => notification.project_id !== projectID);
                    });
                }
            }
        });

    };

    const fetchProjects = async () => {
        if (!token || !user) return;
        try {
            const response = await GetProjects(token, user);
            setProjects(response.data);
        } catch (error) {
            console.error("Error fetching projects: ", error);
        }
    };

    useEffect(() => {
        fetchProjects();
    }, [projectDeleted, valuesFetched]);


    useEffect(() => {
        if (window.location.pathname.startsWith("/project/") && !projectsDropDownOpen) {
            toggleDropdown(1);
        }
    }
        , [window.location.pathname]);

    return (
        <div style={{ width: "100%" }}>
            {/* Side navigation bar */}
            <div>
                {isAuthenticated && (
                    <List
                        sx={{
                            // selected and (selected + hover) states
                            "&& .Mui-selected, && .Mui-selected:hover": {
                                bgcolor: "#E2F5F9",
                                "&, & .MuiListItemIcon-root": {
                                    color: "#20B3CE",
                                },
                            },
                            // hover states
                            "& .MuiListItemButton-root:hover": {
                                bgcolor: "#E2F5F9",
                                "&, & .MuiListItemIcon-root": {
                                    color: "#23C9E8",
                                },
                            },
                            marginTop:
                                user.name !== "" ||
                                    InfoUpdated ||
                                    window.location.pathname === "/profile"
                                    ? "25px"
                                    : "100px",
                            width: "100%",
                            fontFamily: "Avenir Next Cyr",
                        }}
                        disablePadding
                    >
                        {sidebarItems.map(
                            (item, index) => (
                                <div
                                    key={item.text}
                                    className={`${sideBarSelected === index
                                        ? "selectedListItem"
                                        : "listItem"
                                        }`}
                                >
                                    <ListItem
                                        disablePadding
                                        style={{
                                            paddingLeft: "0px",
                                            width: "100%",
                                        }}
                                    >
                                        <ListItemButton
                                            onClick={() => {
                                                handleSidebarClick(index);
                                                toggleDropdown(index);
                                            }}
                                            fullWidth
                                        >
                                            <ListItemIcon
                                                style={{
                                                    minWidth: "40px",
                                                    color: "inherit",
                                                }}
                                            >
                                                {item.icon}
                                            </ListItemIcon>
                                            <p className="sidebar-text">
                                                {item.text}
                                            </p>
                                            {index === 2 && notifications.length > 0 && (
                                                <div className="notification-icon"></div>
                                            )}
                                            {renderDropdownButton(item.text, index)}
                                        </ListItemButton>
                                    </ListItem>
                                    {index === 1 && projectsDropDownOpen && (
                                        <div className="sidebar-items">
                                            <ListItemButton
                                                className="create-button"
                                                onClick={() =>
                                                    setOpenCreateProject(true)
                                                }
                                            >
                                                {t("sidebar.create")}
                                            </ListItemButton>
                                            {projects
                                                ? projects.map((project) => (
                                                    <div
                                                        key={project.id}
                                                        className="sidebar-item flex flex-row"
                                                    >
                                                        <ListItemButton
                                                            key={project.id}
                                                            className="sidebar-item"
                                                            onClick={() =>
                                                                openProject(
                                                                    project._id
                                                                )
                                                            }
                                                            selected={
                                                                window
                                                                    .location
                                                                    .pathname ===
                                                                `/project/${project._id}`
                                                            }
                                                        >
                                                            {
                                                                project.project_name
                                                            }{" "}
                                                            <div
                                                                style={{
                                                                    color: project.project_color
                                                                        ? project.project_color
                                                                        : null,
                                                                    backgroundColor:
                                                                        project.project_color
                                                                            ? project.project_color
                                                                            : null,
                                                                    width: "10px",
                                                                    height: "10px",
                                                                    borderRadius:
                                                                        "50%",
                                                                    marginLeft:
                                                                        "10px",
                                                                }}
                                                            ></div>
                                                        </ListItemButton>
                                                        {user === project.project_creator && (
                                                            <div className="z-10 bg-transparent absolute right-0 mr-4">
                                                                <button
                                                                    style={{
                                                                        color: "#c4c4c4",
                                                                        background:
                                                                            "transparent",
                                                                        border: "none",
                                                                    }}
                                                                    onMouseEnter={(
                                                                        e
                                                                    ) =>
                                                                    (e.currentTarget.style.color =
                                                                        "red")
                                                                    }
                                                                    onMouseLeave={(
                                                                        e
                                                                    ) =>
                                                                    (e.currentTarget.style.color =
                                                                        "#a8a8a8")
                                                                    }
                                                                    onClick={() => {
                                                                        setOpenDeleteProjectModal(
                                                                            true
                                                                        );
                                                                        setSelectedProject(
                                                                            project
                                                                        );
                                                                    }}
                                                                >
                                                                    <DeleteIcon />
                                                                </button>
                                                            </div>
                                                        )}
                                                    </div>

                                                ))
                                                : null}

                                            {openDeleteProjectModal &&
                                                selectedProject && (
                                                    <DeleteProjectModal
                                                        openDeleteProjectModal={
                                                            openDeleteProjectModal
                                                        }
                                                        setOpenDeleteProjectModal={
                                                            setOpenDeleteProjectModal
                                                        }
                                                        projectID={
                                                            selectedProject._id
                                                        }
                                                        projectTitle={
                                                            selectedProject.project_name
                                                        }
                                                        accessToken={token}
                                                        setProjectDeleted={() => {
                                                            // Remove the deleted project from the projects array
                                                            setProjects(
                                                                (
                                                                    prevProjects
                                                                ) =>
                                                                    prevProjects.filter(
                                                                        (
                                                                            proj
                                                                        ) =>
                                                                            proj._id !==
                                                                            selectedProject._id
                                                                    )
                                                            );
                                                            setSelectedProject(
                                                                null
                                                            );
                                                        }}
                                                    />
                                                )}
                                        </div>
                                    )}
                                    {index === 2 && notifsDropDownOpen && (
                                        <div className="sidebar-items">
                                            <div
                                                style={{
                                                    color: "grey",
                                                }}
                                            >
                                                {notifications.length === 0
                                                    ? t("sidebar.noNotifications")
                                                    : notifications.map((notification, index) => (
                                                        <div key={index} className="notification">
                                                            {notification.message}
                                                            <div key={index} className="button-group">
                                                                <button
                                                                    className="accept-button"
                                                                    onClick={() =>
                                                                        handleInvite(
                                                                            true,
                                                                            notification.project_id
                                                                        )
                                                                    }
                                                                >
                                                                    {t("sidebar.acceptInvite")}
                                                                </button>
                                                                <button
                                                                    className="decline-button"
                                                                    onClick={() =>
                                                                        handleInvite(
                                                                            false,
                                                                            notification.project_id
                                                                        )
                                                                    }
                                                                >
                                                                    {t("sidebar.declineInvite")}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )
                        )}
                    </List>
                )}
            </div>
            {openCreateProject && (
                <Modal
                    open={openCreateProject}
                    onClose={() => onCloseCreateProjectModal()}
                >
                    <CreateProjectFlow
                        setOpenCreateProject={setOpenCreateProject}
                        accessToken={token}
                        userID={user}
                        createTrialAfterProject={true}
                    />
                </Modal>
            )}
        </div>
    );
}

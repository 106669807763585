import React, { useState } from "react";
import FileUploadComponent from './FileUpload/FileUploadComponent';
import { FormControl, InputLabel, Select, MenuItem, Input, ThemeProvider, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Alert from '@mui/material/Alert';
import "./SelectImages.css";
import { options } from "../../../utils/enums";
import { theme, ColorButton } from "../../../theme";
import TemplateLadderDropdown from "../../Common/GelElectrophoresisEditor/TemplateLadderDropdown";

const SelectImages = ({
    ProcessImages,
    dilutionFactor,
    volumeUnits,
    assayType,
    setDilutionFactor,
    setVolumeUnits,
    setVolumePlated,
    setFileList,
    setFilenames,
    setAssayType,
    fileList,
    fileNames,
    setSeverity,
    severity,
    message,
    setMessage,
    selectedTemplateLadder,
    setSelectedTemplateLadder,
    setBandMeasurements,
    setBPValueToFind,
    BPMarginOfError,
    setBPMarginOfError,
}) => {
    const [filesReady, setFilesReady] = useState(false);
    const [uploadError, setUploadError] = useState(false);
    const { t } = useTranslation();

    const onFileSubmit = () => {
        console.log("File Submitted");
        if (assayType === "" || assayType === null) {
            setMessage(t("selectImages.errors.selectAssayType"));
            setSeverity("error");
            setTimeout(() => {
                setSeverity(null);
                setMessage("");
            }, 3000);
            return;
        }
        if (filesReady && fileList.length > 0 && fileList.length === fileNames.length && !uploadError) {
            setDilutionFactor(new Array(fileList.length).fill(dilutionFactor));
            ProcessImages();
        }
        else {
            setMessage(t("selectImages.errors.uploadImage"));
            setSeverity("error");
            setTimeout(() => {
                setSeverity(null);
                setMessage("");
            }, 3000);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Box
                className="image-upload-modal-content"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    maxHeight: '100vh',
                    width: '100%',
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', padding: 1 }}>
                    <Box className="modal-inputs">
                        {/* Assay Type */}
                        <FormControl variant="outlined" fullWidth >
                            <InputLabel required>
                                {t("selectImages.assayType.label")}
                            </InputLabel>
                            <Select
                                label={t("selectImages.assayType.label")}
                                onChange={(e) => setAssayType(e.target.value)}
                                value={assayType}
                            >
                                {options.map((option) => (
                                    <MenuItem value={option.value} key={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {/* Volume Plated */}
                        {assayType !== "Gel Electrophoresis" && (
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel>{t("selectImages.volumePlated.label")}</InputLabel>
                                <Input
                                    type="number"
                                    onChange={(e) => setVolumePlated(e.target.value)}
                                    endAdornment={
                                        <Select
                                            value={volumeUnits || "ml"}
                                            onChange={(e) => setVolumeUnits(e.target.value)}
                                            variant="standard"
                                        >
                                            <MenuItem value="ml">ml</MenuItem>
                                            <MenuItem value="ul">ul</MenuItem>
                                        </Select>
                                    }
                                />
                            </FormControl>
                        )}

                        {/* Dilution Factor */}
                        {assayType !== "Gel Electrophoresis" && (
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel>{t("selectImages.dilutionFactor.label")}</InputLabel>
                                <Input
                                    type="number"
                                    onChange={(e) => setDilutionFactor(e.target.value)}
                                />
                            </FormControl>
                        )}

                        {/* Ladder Band (E Gel Only) */}
                        {assayType === "Gel Electrophoresis" && (
                            <>
                                <FormControl variant="outlined" fullWidth >
                                    <TemplateLadderDropdown
                                        setSelectedTemplateLadder={setSelectedTemplateLadder}
                                        selectedTemplateLadder={selectedTemplateLadder}
                                        setBandMeasurements={setBandMeasurements}
                                    />
                                </FormControl>
                                <FormControl variant="outlined" fullWidth >
                                    <InputLabel>{t("selectImages.bpValueToFind.label")}</InputLabel>
                                    <Input
                                        type="number"
                                        onChange={(e) => setBPValueToFind(e.target.value)}
                                    />
                                </FormControl>
                                <FormControl variant="outlined" fullWidth >
                                    <InputLabel>{t("selectImages.bpMarginOfError.label")}</InputLabel>
                                    <Input
                                        type="number"
                                        onChange={(e) => setBPMarginOfError(e.target.value)}
                                    />
                                </FormControl>
                            </>
                        )}
                    </Box>
                    {assayType === "Gel Electrophoresis" && (
                        <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 2 }}>
                            {t("selectImages.eGelNote")}
                        </Typography>
                    )}
                </Box>

                <Box sx={{ width: "100%" }}>
                    <FileUploadComponent
                        setFilesReady={setFilesReady}
                        setUploadError={setUploadError}
                        fileList={fileList}
                        setFileList={setFileList}
                        fileNames={fileNames}
                        setFilenames={setFilenames}
                        setMessage={setMessage}
                        setSeverity={setSeverity}
                        rotateImage={assayType !== "Gel Electrophoresis"}
                    />
                </Box>

                <Box
                    className="analyze-button-footer"
                    sx={{
                        marginTop: 'auto',
                        borderTop: '1px solid #e0e0e0',
                        backgroundColor: 'background.paper',
                        position: 'sticky',
                        bottom: 0,
                        padding: 2,
                        width: '100%',
                        height: 'auto',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                    }}
                >
                    {(severity === 'error' || severity === 'success') && (
                        <Alert severity={severity} sx={{ marginBottom: 2 }}>{message}</Alert>
                    )}
                    <ColorButton
                        variant="contained"
                        onClick={onFileSubmit}
                        disabled={fileList.length < 1}
                        sx={{ alignSelf: 'flex-end' }}
                    >
                        {t("selectImages.analyzeImages")}
                    </ColorButton>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default SelectImages;
import { callExternalApi } from "./external-api.service";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const UploadFile = async (accessToken, formData) => {
  const config = {
    url: `${apiServerUrl}/models/upload`,
    method: "POST",
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
    },
    data: formData,
  };
  
  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};
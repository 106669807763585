import { useAuth0 } from "@auth0/auth0-react";
import { ThemeProvider } from "@emotion/react";
import { Box, TextField } from "@mui/material";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../components/Common/Button/Button";
import Message from "../../components/Common/Message/Message";
import { SaveComment } from "../../services/comments.service";
import { theme } from "../../theme";
import About from "./About/About";
import Advisors from "./Advisors/Advisors";
import "./Contact.css";
import Founders from "./Founders/Founders";
import Team from "./Team/Team";

function Contact() {
    const { t } = useTranslation();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [messageField, setMessageField] = useState("");
    const [message, setMessage] = useState(null);
    const [messageType, setMessageType] = useState(null);

    //----------Service Functions----------//
    const onclick = async () => {
        if (!name || !email || !subject || !messageField) {
            getMessage(
                null,
                "Please fill in all fields",
                "Comment Submitted!",
                "Please fill in all fields"
            );
            return;
        }
        // regex for email
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (!emailRegex.test(email)) {
            getMessage(
                null,
                "Please enter a valid email",
                "Comment Submitted!",
                "Please enter a valid email"
            );
            return;
        }
        // regex for name
        const nameRegex = /^[a-zA-Z\s]*$/;
        if (!nameRegex.test(name)) {
            getMessage(
                null,
                "Please enter a valid name",
                "Comment Submitted!",
                "Please enter a valid name"
            );
            return;
        }
        // regex for subject
        const subjectRegex = /^[a-zA-Z\s]*$/;
        if (!subjectRegex.test(subject)) {
            getMessage(
                null,
                "Please enter a valid subject",
                "Comment Submitted!",
                "Please enter a valid subject"
            );
            return;
        }

        // create form data
        const formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("subject", subject);
        formData.append("message", messageField);

        const { data, error } = await SaveComment(formData);
        getMessage(
            data,
            error,
            "Comment Submitted!",
            "Error Submitting Comment"
        );
    };

    //----------Helper Functions----------//
    const getMessage = (data, error, successMsg, errorMsg) => {
        // display error or success message
        document.getElementById("messageDiv").hidden = false;
        if (data) {
            // set error to null
            setMessageType("success");
            setMessage(successMsg);
        }
        if (error) {
            // set error to error message for 5 seconds
            setMessageType("error");
            setMessage(errorMsg);
        }
        setTimeout(() => {
            // set message div to hidden after 5 seconds
            document.getElementById("messageDiv").hidden = true;
        }, 3000);
    };

    const openLink = () => {
        window.open("https://meetings.hubspot.com/ali-mehdi1", "_blank");
    };

    // prefill form if user is signed in
    const { user } = useAuth0();
    if (user) {
        setName(user.name);
        setEmail(user.email);
    }

    return (
        <ThemeProvider theme={theme}>
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                <Box className="contact-section">
                    <Box className="contact-text-section">
                        <p className="contact-section-title">
                            {t("contact.title")}
                        </p>
                        <p className="contact-subtitle">
                            {t("contact.subtitle")}
                        </p>
                        <Button
                            variant="contained"
                            color="primary"
                            className="book-demo-button"
                            onClick={() => {
                                openLink();
                            }}
                        >
                            {t("contact.bookDemo")}
                        </Button>
                        <div className="book-demo-subtitle">
                            {t("contact.emailIntro")}
                            <span>
                                {" "}
                                <a
                                    href="mailto:inquiries@pytri.ca"
                                    className="email-link"
                                >
                                    {" inquiries@pytri.ca "}
                                </a>{" "}
                            </span>
                            {t("contact.salesEmailText")}
                            <span>
                                <a
                                    href="mailto:info@pytri.ca"
                                    className="email-link"
                                >
                                    {" support@pytri.ca "}
                                </a>
                            </span>
                            {t("contact.supportEmailText")}
                        </div>
                    </Box>
                    <div className="contact-form-container">
                        <form onSubmit={(e) => e.preventDefault()}>
                            <div className="input-field-container">
                                <TextField
                                    fullWidth
                                    label={t("contact.formName")}
                                    placeholder={name}
                                    required
                                    onChange={(e) => {
                                        setName(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="input-field-container">
                                <TextField
                                    fullWidth
                                    label={t("contact.formEmail")}
                                    placeholder={email}
                                    required
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="input-field-container">
                                <TextField
                                    fullWidth
                                    label={t("contact.formSubject")}
                                    placeholder={subject}
                                    required
                                    onChange={(e) => {
                                        setSubject(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="input-field-container">
                                <TextField
                                    fullWidth
                                    label={t("contact.formMessage")}
                                    placeholder={messageField}
                                    required
                                    onChange={(e) => {
                                        setMessageField(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="submit-btn-container">
                                <Button
                                    pill
                                    size="large"
                                    variant="secondary"
                                    onClick={onclick}
                                    className="submit-button"
                                >
                                    {t("contact.formSubmit")}
                                </Button>
                            </div>
                        </form>
                    </div>
                    <div
                        className="fixed bottom-0 right-0 p-4"
                        id="messageDiv"
                        hidden
                    >
                        <Message type={messageType}>{message}</Message>
                    </div>
                </Box>
                <About />
                <Advisors />
                <Founders />
                <Team />
            </motion.div>
        </ThemeProvider>
    );
}

export default Contact;
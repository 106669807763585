import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  ThemeProvider,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import './Pricing.css';
import { theme } from '../../theme';
import { ColorButton } from '../../theme';
import { motion } from "framer-motion";

const PricingCard = ({ title, description, buttonText, features, isHighlighted }) => {
  return (
    <Card className={`pricing-card ${isHighlighted ? 'highlighted' : ''}`}>
      <CardContent className="card-content">
        <Typography variant="h6" component="h3" className="card-title">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary" className="card-description">
          {description}
        </Typography>
        <ColorButton variant="contained" color="primary" className="card-button">
          {buttonText}
        </ColorButton>
        <ul className="feature-list">
          {features.map((feature, index) => (
            <li key={index} className="feature-item">
              <CheckIcon color="primary" fontSize="small" />
              <Typography variant="body2" className="feature-text">
                {feature}
              </Typography>
            </li>
          ))}
        </ul>
      </CardContent>
    </Card>
  );
};

const Pricing = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const plans = [
    {
      title: t("pricing.explorer.name"),
      description: t("pricing.explorer.desc"),
      buttonText: t("pricing.uploadNow"),
      features: [
        t("pricing.features.limitedDataProcessing"),
        t("pricing.features.experimentArchives"),
        t("pricing.features.automatedReportGeneration"),
        t("pricing.features.free"),
      ],
    },
    {
      title: t("pricing.academic.name"),
      description: t("pricing.academic.desc"),
      buttonText: t("pricing.getInTouch"),
      features: [
        t("pricing.features.unlimitedDataProcessing"),
        t("pricing.features.experimentArchives"),
        t("pricing.features.automatedReportCreation"),
      ],
      isHighlighted: true
    },
    {
      title: t("pricing.laboratory.name"),
      description: t("pricing.laboratory.desc"),
      buttonText: t("pricing.getInTouch"),
      features: [
        t("pricing.features.unlimitedDataProcessing"),
        t("pricing.features.experimentArchives"),
        t("pricing.features.automatedReportCreation"),
        t("pricing.features.teamCollaboration"),
      ],
    },
    {
      title: t("pricing.enterprise.name"),
      description: t("pricing.enterprise.desc"),
      buttonText: t("pricing.getInTouch"),
      features: [
        t("pricing.features.unlimitedDataProcessing"),
        t("pricing.features.experimentArchives"),
        t("pricing.features.automatedReportCreation"),
        t("pricing.features.teamCollaboration"),
        t("pricing.features.tailoredSolutions"),
      ],
    }
  ];

  return (
    <ThemeProvider theme={theme}>
	   	<motion.div
	        initial={{ opacity: 0 }}
	        animate={{ opacity: 1 }}
	        exit={{ opacity: 0 }}
	    >
		    <div className="pricing-container">
		      <Container maxWidth="lg">
		        <Box className="header-container">
		          <Typography variant="h4" component="h1" className="main-title">
		            {t("pricing.header")}
		          </Typography>
		          <Typography variant="subtitle1" className="sub-title">
		            {t("pricing.subHeader")}
		          </Typography>
		        </Box>
		        <Grid container spacing={3}>
		          {plans.map((plan, index) => (
		            <Grid item key={index} xs={12} sm={6} md={3}>
		              <PricingCard {...plan} />
		            </Grid>
		          ))}
		        </Grid>
		      </Container>
		    </div>
	    </motion.div>
    </ThemeProvider>
  );
};

export default Pricing;
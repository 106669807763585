// React
import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Routes, Route, useLocation } from "react-router-dom";
import { AuthenticationGuard } from "./Authentication/authentication-guard";
import { AdminAuthenticationGuard } from "./Authentication/admin-authentication-guard";
import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";
// Redirects
import Login from "./Authentication/Login";
import Logout from "./Authentication/Logout";
// Page imports
import Home from "./pages/Home/Home";
import Profile from "./pages/Profile/Profile";
import Dashboard from "./pages/Dashboard/Dashboard.js";
import Contact from "./pages/Contact/Contact.js";
import Pricing from "./pages/Pricing/Pricing.js";
import Products from "./pages/Products/Products.js";
import VerifyEmail from "./pages/VerifyEmail/VerifyEmail.js";
import Project from "./pages/Project/Project";
// Component imports
import DashboardSidebar from "./components/Sidebar/dashboard-sidebar";
import NavBar from "./components/TopNavBar/NavBar";
// import Footer from "./components/Footer/footer.jsx";
// import { Button } from "@mui/material";
// Services
import { GetUserByID } from "./services/user.service";
// CSS
import "./App.css";
import "./assets/fonts/stylesheet.css";
// Animations
import { AnimatePresence } from "framer-motion";

const gtmID = process.env.REACT_APP_GTM_ID;
const environment = process.env.REACT_APP_ENV;
if (environment === "production") {
  TagManager.initialize({ gtmId: gtmID });
}

function App() {
  //----------Hooks----------//
  const { t } = useTranslation();
  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();
  //----------States----------//
  const [token, setToken] = React.useState(null);
  const [userData, setUserData] = React.useState({});
  const location = useLocation();
  const [projectID, setProjectID] = React.useState(null);
  const [sideBarSelected, setSideBarSelected] = React.useState(null);
  const [ping, setPing] = useState(false);
  const [projectDeleted, setProjectDeleted] = useState(null);

  //----------Use Effects----------//

  // get user data on page load
  // TODO: Only retrieve InfoUpdated field from backend
  useEffect(() => {
    if (!user) {
      return;
    }
    const fetchUser = async () => {
      const token = await getAccessTokenSilently();
      setToken(token);
      try {
        const { data } = await GetUserByID(
          token,
          user.sub.split("|")[1],
          user.email
        );

        if (data) {
          setUserData(data);
        }
      } catch (e) {
        console.error(e);
      }
    };
    fetchUser();
  }, [useAuth0, user, getAccessTokenSilently]);

  return (
    <div className="app-wrapper">
      <AnimatePresence>
        {/*----------Header Area----------*/}

        <NavBar userData={userData} InfoUpdated={userData.InfoUpdated} />

        {user &&
        user.email_verified === true &&
        (location.pathname === "/history" ||
          location.pathname === "/profile" ||
          location.pathname === "/detection" ||
          location.pathname.startsWith("/project/") ||
          location.pathname === "/settings") ? (
          <div className="sidebar-container">
            <DashboardSidebar
              token={token}
              user={user.sub.split("|")[1]}
              sideBarSelected={sideBarSelected}
              setSideBarSelected={setSideBarSelected}
              InfoUpdated={userData.InfoUpdated}
              userData={userData}
              projectDeleted={projectDeleted}
            />
          </div>
        ) : null}

        {/*----------Main Content Area----------*/}
        <div
          className={
            (isAuthenticated &&
            user &&
            user.email_verified === true &&
            (location.pathname === "/history" ||
              location.pathname === "/profile" ||
              location.pathname.startsWith("/project/") ||
              location.pathname === "/settings" ||
              location.pathname === "/detection")
              ? "main-content-container-authenticated"
              : "main-content-container-unauthenticated") +
            (isAuthenticated &&
            user.InfoUpdated === false &&
            window.location.pathname !== "/profile"
              ? " main-content-container"
              : "")
          }
        >
          {user && user.email_verified === false ? (
            <div>
              <VerifyEmail />
              <Routes>
                <Route path="/logout" element={<Logout />} />
              </Routes>
            </div>
          ) : (
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route
                path="/profile"
                element={
                  <AuthenticationGuard
                    component={Profile}
                    accessToken={token}
                    user={user}
                  />
                }
              />
              <Route
                path="/project/:projectID"
                element={
                  <AuthenticationGuard
                    component={Project}
                    accessToken={token}
                    user={user}
                    setProjectDeleted={setProjectDeleted}
                  />
                }
              />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/login" element={<Login />} />
              <Route path="/Logout" element={<Logout />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/products" element={<Products />} />
              <Route
                path="/history"
                element={
                  <AuthenticationGuard
                    component={Dashboard}
                    userData={userData}
                    accessToken={token}
                    setProjectID={setProjectID}
                    setSideBarSelected={setSideBarSelected}
                  />
                }
              />
            </Routes>
          )}
        </div>

        {/* ----------Footer----------
      <div>
        <Footer />
      </div> */}
      </AnimatePresence>
    </div>
  );
}

export default App;

import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import './CloseConfirmationModal.css';
import { useTranslation } from 'react-i18next';

const CloseConfirmationModal = ({ openConfirmationModal, setOpenConfirmationModal, setOpenImageUploadModal }) => {
    const { t } = useTranslation();

    const styleCloseConfirmation = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        width: '30%',
        height: '40%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        overflow: 'auto',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '10px',
    };

    return (
        <Modal
            open={openConfirmationModal}
            onClose={() => setOpenConfirmationModal(false)}
        >
            <Box className="close-confirmation-container">
                <div className="modal-header">
                    <h2 className="modal-title">{t('selectImages.closeConfirmationModal.title')}</h2>
                    <div className="modal-close" onClick={() => setOpenConfirmationModal(false)}>
                        X
                    </div>
                </div>
                <div className="modal-body">
                    <div className="exit-confirm-container">
                        <h3 style={{ textAlign: "center" }}>{t('selectImages.closeConfirmationModal.message')}</h3>
                        <div className="button-container">
                            <div className="confirm-button" onClick={() => { setOpenImageUploadModal(false); setOpenConfirmationModal(false) }}>
                                <button style={{ width: "100%" }}>{t('selectImages.closeConfirmationModal.yes')}</button>
                            </div>
                            <div className="cancel-button" onClick={() => setOpenConfirmationModal(false)}>
                                <button style={{ width: "100%" }}>{t('selectImages.closeConfirmationModal.no')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
    );
};

export default CloseConfirmationModal;
import React, { useState, useEffect } from "react";
import "./ReviewImages.css";
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import {
    TextField,
    LinearProgress,
} from "@mui/material";
import { BloodSmearRBCTypes, BloodSmearWBCTypes } from "../../../utils/enums";
import ImageEditor from "../../Common/ImageEditor/ImageEditor";
import { useTranslation } from 'react-i18next';

function ReviewImages({
    adjustedBoundingBoxes,
    setAdjustedBoundingBoxes,
    newCounts,
    setNewCounts,
    setDilutionFactor,
    imagesProcessed,
    fileList,
    fileNames,
    setfileNames,
    assayType,
    dilutionFactor,
    boundingBoxesList,
    setConfirmResults,
    sensitivity,
    notes,
    setNotes,
    bandMeasurements,
    setBandMeasurements,
    setClass0MeasurementsInClass4,
    class0MeasurementsInClass4,
    setSelectedTemplateLadder,
    selectedTemplateLadder,
    selectedWells,
    setSelectedWells,
    BPValueToFind,
    setBPValueToFind,
    BPMarginOfError,
    setBPMarginOfError
}) {
    const [index, setIndex] = useState(0);
    const [base64Images, setBase64Images] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        const convertImagesToBase64 = async () => {
            const promises = fileList.map(file => getBase64Image(file));
            const base64Files = await Promise.all(promises);
            setBase64Images(base64Files);
        };

        convertImagesToBase64();
    }, [fileList]);

    const getBase64Image = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                // Remove the prefix
                const base64String = reader.result.split(',')[1];
                resolve(base64String);
            };
            reader.onerror = error => reject(error);
            reader.readAsDataURL(file);
        });
    };

    const getCellTypes = (assay) => {
        let cellTypes = [];
        if (assay === "Smear") {
            Object.keys(BloodSmearRBCTypes).forEach((key) => {
                cellTypes.push(BloodSmearRBCTypes[key]);
            });
        } else if (assay === "Smear WBC") {
            Object.keys(BloodSmearWBCTypes).forEach((key) => {
                cellTypes.push(BloodSmearWBCTypes[key]);
            });
        } else if (assay === "GFP/OFP") {
            cellTypes.push("GFP");
            cellTypes.push("OFP");
        } else if (assay === "Membrane") {
            cellTypes.push("E. Coli");
            cellTypes.push("Other Coliform");
        } else {
            cellTypes.push("Colony");
        }
        return cellTypes;
    };

    return (
        <div className="review-container">
            <div className="image-review-container">
                <div className="image-pagination-container">
                    <h2>{t('selectImages.reviewImages.title')}</h2>
                    <div className="load-container">
                        <div className="image-pagination">
                            <button
                                onClick={() => {
                                    if (index > 0) {
                                        setIndex(index - 1);
                                    }
                                }}
                            >
                                <KeyboardArrowLeftRoundedIcon />
                            </button>
                            <p className="pagination-counter">
                                {index + 1} {t('selectImages.reviewImages.pagination.of')} {imagesProcessed}
                            </p>
                            <button
                                onClick={() => {
                                    if (index < imagesProcessed - 1) {
                                        setIndex(index + 1);
                                    }
                                }}
                            >
                                <KeyboardArrowRightRoundedIcon />
                            </button>
                        </div>
                        {fileList.length !== imagesProcessed &&
                            <div style={{ width: "50%" }}>
                                <LinearProgress variant="determinate" value={(imagesProcessed) / (fileList.length) * 100} />
                            </div>}
                    </div>
                    {imagesProcessed === fileList.length &&
                        (<div className="modal-footer-buttons">
                            <button className="skip-button"
                                disabled={fileList.length !== imagesProcessed}
                                onClick={() => { setConfirmResults(); }}
                            >
                                {t('selectImages.reviewImages.buttons.finish')}
                            </button>
                        </div>)}
                </div>

                {base64Images.length > 0 && boundingBoxesList.length > 0 && boundingBoxesList[index] && (
                    <ImageEditor
                        imageData={{
                            bounding_boxes: boundingBoxesList[index],
                            sensitivity: sensitivity[index],
                            assay_type: assayType,
                        }}
                        newCounts={newCounts}
                        setNewCounts={setNewCounts}
                        setAdjustedBoundingBoxes={setAdjustedBoundingBoxes}
                        adjustedBoundingBoxes={adjustedBoundingBoxes}
                        multiclassModel={assayType === "Smear" || assayType === "Smear WBC" || assayType === "GFP/OFP"}
                        accessToken={null}
                        cellTypes={getCellTypes(assayType)}
                        showHelperMessage={false}
                        horizontalControls={true}
                        imageFile={base64Images[index]}
                        index={index}
                        ImageIndex={index}
                        bandMeasurements={bandMeasurements}
                        setBandMeasurements={setBandMeasurements}
                        setSelectedTemplateLadder={setSelectedTemplateLadder}
                        selectedTemplateLadder={selectedTemplateLadder}
                        setClass0MeasurementsInClass4={setClass0MeasurementsInClass4}
                        class0MeasurementsInClass4={class0MeasurementsInClass4}
                        selectedWells={selectedWells}
                        setSelectedWells={setSelectedWells}
                        BPValueToFind={BPValueToFind}
                        setBPValueToFind={setBPValueToFind}
                        BPMarginOfError={BPMarginOfError}
                        setBPMarginOfError={setBPMarginOfError}
                    />
                )}

            </div>
            <div className="image-info">
                <TextField
                    label={t('selectImages.reviewImages.fields.imageName')}
                    margin="normal"
                    variant="standard"
                    fullWidth
                    focused
                    value={fileNames[index] ? fileNames[index].split(".")[0] : ""}
                    onChange={(e) => {
                        const newImageNames = [...fileNames];
                        newImageNames[index] = e.target.value + "." + newImageNames[index].split(".")[1];
                        setfileNames(newImageNames);
                    }}
                />
                {assayType !== "Gel Electrophoresis" && dilutionFactor.length > 0 && dilutionFactor[index] !== undefined &&
                    <TextField
                        label={t('selectImages.reviewImages.fields.dilutionFactor')}
                        margin="normal"
                        variant="standard"
                        fullWidth
                        focused
                        value={dilutionFactor[index] === null ? "" : dilutionFactor[index]}
                        type="number"
                        onChange={(e) => {
                            const newDilutionFactor = [...dilutionFactor];
                            newDilutionFactor[index] = e.target.value;
                            setDilutionFactor(newDilutionFactor);
                        }}
                    />}
            </div>
            <div className="image-info">
                <TextField
                    label={t('selectImages.reviewImages.fields.notes')}
                    margin="normal"
                    variant="outlined"
                    multiline
                    rows={2}
                    focused
                    fullWidth
                    value={notes[index]}
                    onChange={(e) => {
                        const newNotes = [...notes];
                        newNotes[index] = e.target.value;
                        setNotes(newNotes);
                    }}
                />
            </div>
        </div>
    );
}

export default ReviewImages;
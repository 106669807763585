import React, { useRef, useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { GetProjectImage, UpdateImage } from "../../../services/project.service";
import {
    Box, Grid, Paper, Alert, ThemeProvider, FormControl, InputLabel, Input
} from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Tutorial from "./Tutorial";
import "./GelElectrophoresisEditor.css";
import { theme, ColorButton } from "../../../theme";
import ToolBar from "../ToolBar/ToolBar";

import TemplateLadderDropdown from "./TemplateLadderDropdown";
import { DNALadders, RNALadders, ProteinLadders } from "../../../utils/enums";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';


const GelElectrophoresisEditor = ({
    imageFile,
    imageData,
    bandMeasurements,
    setBandMeasurements,
    setClass0MeasurementsInClass4,
    setSelectedTemplateLadder,
    selectedTemplateLadder,
    ImageIndex,
    adjustedBoundingBoxes,
    setAdjustedBoundingBoxes,
    accessToken,
    selectedWells,
    setSelectedWells,
    BPValueToFind,
    setBPValueToFind,
    BPMarginOfError,
    setBPMarginOfError
}) => {
    const { t } = useTranslation();
    const canvasRef = useRef(null);
    const toolbarRef = useRef(null);
    const imageContainerRef = useRef(null);
    const containerRef = useRef(null);
    const [base64Image, setBase64Image] = useState(null);
    const [tutorialOpen, setTutorialOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");
    const [zoom] = useState(1);
    const [selectedBox, setSelectedBox] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const [dragStartPoint, setDragStartPoint] = useState({ x: 0, y: 0 });
    const [dragBox, setDragBox] = useState(null);
    const [disableZoomPan, setDisableZoomPan] = useState(false);
    const [currentClass0MeasurementsInClass4, setCurrentClass0MeasurementsInClass4] = useState({});
    const [imageHeight, setImageHeight] = useState("fit-content");
    const [imageWidth, setImageWidth] = useState("fit-content");
    const [classColors] = useState({ 0: "#E63946", 3: "#FFD166", 2: "#6A4C93" });

    const [isSavedImage, setIsSavedImage] = useState(false);
    const [savedImageBoundingBoxes, setSavedImageBoundingBoxes] = useState(null);
    const [savedImageData, setSavedImageData] = useState(null);
    const [linePosition, setLinePosition] = useState({});
    const [isDraggingLine, setIsDraggingLine] = useState(false);
    const [showHandles, setShowHandles] = useState(false);
    const [imageBeingDragged, setImageBeingDragged] = useState(null);
    const [isDraggingImage, setIsDraggingImage] = useState(false);

    // when first loading image or switching images
    useEffect(() => {
        // set new base64 image
        if (isSavedImage === false && imageFile !== null) {
            setBase64Image(imageFile);
            // filter bounding boxes for current image

        }

        if (selectedTemplateLadder !== null) {
            setBandMeasurements(selectedTemplateLadder.bands);
        }

        // undo box select
        setSelectedBox(null);
    }, [imageFile]);


    const updateImageHeight = () => {
        if (imageContainerRef.current) {
            setImageHeight(imageContainerRef.current.clientHeight);
            setImageWidth(imageContainerRef.current.clientWidth);
        } else {
            // get height another way
            let image = new Image();
            image.src = "data:image/jpg;base64," + base64Image;
            image.onload = () => {
                setImageHeight(image.height);
                setImageWidth(image.width);
            }

        }
    };

    useEffect(() => {
        updateImageHeight();
        window.addEventListener('resize', updateImageHeight);

        return () => {
            window.removeEventListener('resize', updateImageHeight);
        };
    }, [base64Image]);

    // when image is open from dashboard, grab image base64 and image data
    useEffect(() => {
        async function fetchImage() {
            if (imageData !== null && accessToken !== null) {
                console.log("fetching image");
                let formData = new FormData();

                formData.append("filename", imageData._id + "." + imageData.filename.split(".")[imageData.filename.split(".").length - 1]);
                formData.append("image_id", imageData._id);
                formData.append("trial_id", imageData.trial_id);

                await GetProjectImage(accessToken, formData).then((response) => {
                    if (response.error) {
                        setSeverity("error");
                        setMessage(t("bboxEditor.imageNotFound"));
                    } else {
                        setIsSavedImage(true);
                        setBase64Image(response.data.image);
                        setSavedImageBoundingBoxes(response.data.imageData.bounding_boxes);
                        setSavedImageData(response.data.imageData);
                        setBandMeasurements(response.data.imageData.band_measurements);
                        // set selected template ladder from name
                        setSelectedTemplateLadder(DNALadders[response.data.imageData.template_ladder] || RNALadders[response.data.imageData.template_ladder] || ProteinLadders[response.data.imageData.template_ladder]);
                        // check if states exist for selectedWells and create them if they don't
                        setSelectedWells(response.data.imageData.selected_wells);
                        setBPValueToFind(response.data.imageData.bp_value_to_find);
                        setBPMarginOfError(response.data.imageData.bp_margin_of_error);

                    }
                });
            } else if (
                accessToken === null &&
                imageFile !== null &&
                imageData !== null
            ) {
                setBase64Image(imageFile);
                setIsSavedImage(false);
                setSavedImageBoundingBoxes(null);
                if (imageData.bounding_boxes.length > 0 & setAdjustedBoundingBoxes !== null && adjustedBoundingBoxes[ImageIndex].length === 0) {
                    setAdjustedBoundingBoxes(prev => {
                        const newPrev = [...prev];
                        newPrev[ImageIndex] = imageData.bounding_boxes;
                        return newPrev;
                    });
                }
            } else {
                setSeverity("error");
                setMessage(t("bboxEditor.noBoundingBoxes"));
            }
        }
        fetchImage();
    }, [imageData, imageFile, accessToken, ImageIndex, t]);


    const SaveChangesOnImage = () => {
        let formData = new FormData();

        savedImageData.bounding_boxes = savedImageBoundingBoxes;
        savedImageData.band_measurements = bandMeasurements;
        savedImageData.template_ladder = Object.keys(DNALadders).find(key => DNALadders[key] === selectedTemplateLadder) || Object.keys(RNALadders).find(key => RNALadders[key] === selectedTemplateLadder) || Object.keys(ProteinLadders).find(key => ProteinLadders[key] === selectedTemplateLadder) || '';
        savedImageData.selected_wells = selectedWells;
        savedImageData.bp_value_to_find = BPValueToFind;
        savedImageData.bp_margin_of_error = BPMarginOfError;
        formData.append("image", JSON.stringify(savedImageData));

        UpdateImage(accessToken, formData).then((response) => {
            if (response.error) {
                setSeverity("error");
                setMessage(t("bboxEditor.imageUpdateError"));
                setTimeout(() => {
                    setSeverity("");
                    setMessage("");
                }, 3000);
            } else {
                setSeverity("success");
                setMessage(t("bboxEditor.imageUpdateSuccess"));
                setTimeout(() => {
                    setSeverity("");
                    setMessage("");
                }, 3000);
            }
        });
    };

    const shouldDrawBand = (cls) => {
        if (cls === 1) {
            return false;
        }
        return true;
    };

    const SortAndFilterBboxes = (currentImageBoundingBoxes) => {

        // filter overlapping boxes
        currentImageBoundingBoxes = currentImageBoundingBoxes.reduce((acc, box, index) => {
            const [xmin, ymin, xmax, ymax] = box;
            const currentBoxArea = (xmax - xmin) * (ymax - ymin);

            // Check if this box significantly overlaps with any box we've already decided to keep
            const hasSignificantOverlap = acc.some((keptBox) => {
                const [keptXmin, keptYmin, keptXmax, keptYmax] = keptBox;
                const keptBoxArea = (keptXmax - keptXmin) * (keptYmax - keptYmin);

                const xOverlap = Math.max(0, Math.min(xmax, keptXmax) - Math.max(xmin, keptXmin));
                const yOverlap = Math.max(0, Math.min(ymax, keptYmax) - Math.max(ymin, keptYmin));
                const overlapArea = xOverlap * yOverlap;
                const unionArea = currentBoxArea + keptBoxArea - overlapArea;

                // If the overlap area is more than 80% of the union area, consider the boxes the same
                if (overlapArea / unionArea > 0.1
                    && box[4] === keptBox[4]
                ) return true
            });

            // If this box doesn't significantly overlap with any kept box, add it to the result
            if (!hasSignificantOverlap) {
                acc.push(box);
            }

            return acc;
        }, []);

        // Sort class 2 boxes by x coordinate
        currentImageBoundingBoxes = currentImageBoundingBoxes.sort((a, b) => a[0] - b[0]);

        return currentImageBoundingBoxes;
    };

    const renderAnnotations = () => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const context = canvas.getContext('2d');
        let currentImageBoundingBoxes = isSavedImage ? savedImageBoundingBoxes : adjustedBoundingBoxes[ImageIndex]

        // remove boxes of class 1
        currentImageBoundingBoxes = currentImageBoundingBoxes.filter(([, , , , cls]) => cls !== 1);

        // draw a line under the lowest purple band
        let purpleBands = currentImageBoundingBoxes.filter(([, , , , cls]) => cls === 2);
        const lowestPurpleBand = currentImageBoundingBoxes.filter(([, , , , cls]) => cls === 2).sort((a, b) => b[3] - a[3])[0];

        // anything above well threshold should be class 2
        currentImageBoundingBoxes = currentImageBoundingBoxes.map((box) => {
            if (box[3] < linePosition[ImageIndex]) {
                box[4] = 2;
            } else {
                box[4] = 0;
            }
            return box;
        });




        // sort and filter bounding boxes
        currentImageBoundingBoxes = SortAndFilterBboxes(currentImageBoundingBoxes);

        // Create a map of class 4 annotations to their index in the sorted array
        const class4IndexMap = new Map();
        currentImageBoundingBoxes.forEach(([, , , , cls], index) => {
            if (cls === 2) class4IndexMap.set(index, class4IndexMap.size);
        });


        currentImageBoundingBoxes.forEach((band, index) => {
            const [xmin, ymin, xmax, ymax, cls] = band;
            // group bands into columns based on x coordinates alignment with purple bands
            const closestPurpleBand = purpleBands.reduce((acc, band) => {
                const bandIndex = currentImageBoundingBoxes.indexOf(band);
                const bandCenterX = (band[0] + band[2]) / 2;
                const currentBandCenterX = (xmin + xmax) / 2;
                const distance = Math.abs(bandCenterX - currentBandCenterX);
                if (distance < acc.distance) {
                    return { distance, bandIndex };

                }
                return acc;
            }, { distance: Infinity, bandIndex: null });

            if (band[4] !== 2) {
                // if the closest purple band is a selected well, the band should be yellow
                // get index of closest purple band
                const sortedPurpleBoxes = currentImageBoundingBoxes.filter(([, , , , cls]) => cls === 2).sort((a, b) => a[0] - b[0]);
                const closestPurpleBandIndex = sortedPurpleBoxes.indexOf(currentImageBoundingBoxes[closestPurpleBand.bandIndex]);
                if (selectedWells.includes(closestPurpleBandIndex)) {
                    band[4] = 3;
                } else if (closestPurpleBand.bandIndex !== null) {
                    band[4] = 0;
                }
            }

            if (shouldDrawBand(band[4])) {
                // fill background of box with white
                context.fillStyle = "white";
                // change opacity of box based on class
                context.globalAlpha = 0.7;
                context.fillRect(xmin * zoom, ymin * zoom, (xmax - xmin) * zoom, (ymax - ymin) * zoom);
                // add border to box
                context.lineWidth = 5;
                context.strokeStyle = classColors[band[4]];
                context.strokeRect( // draw box 
                    xmin * zoom, ymin * zoom, (xmax - xmin) * zoom, (ymax - ymin) * zoom
                );
            }

            // if purple band, label with index
            if (band[4] === 2) {
                context.fillStyle = "black";
                // pick font size based on zoom level and box size
                context.font = `${Math.min(20, (xmax - xmin) * zoom / 2)}px Arial`;
                context.textAlign = "center";
                context.textBaseline = "middle";
                context.fillText(class4IndexMap.get(index) + 1, (xmin + xmax) / 2 * zoom, (ymin + ymax) / 2 * zoom);
            }

            // if box is also selected box, draw dashed white border
            if (selectedBox === band) {
                context.setLineDash([5]);
                context.strokeStyle = "white";
                context.strokeRect(
                    xmin * zoom,
                    ymin * zoom,
                    (xmax - xmin) * zoom,
                    (ymax - ymin) * zoom
                );
                context.setLineDash([]);
            }

            // if line position is not set for this image index, set it to the lowest purple band
            if (linePosition[ImageIndex] === undefined) {
                linePosition[ImageIndex] = lowestPurpleBand[3] + 10;
            }

            context.strokeStyle = "rgba(255, 255, 255, 0.8)";
            context.lineWidth = 3;
            context.setLineDash([10, 5]); // Create a dashed line
            context.beginPath();
            context.moveTo(0, linePosition[ImageIndex] * zoom);
            context.lineTo(canvas.width, linePosition[ImageIndex] * zoom);
            context.stroke();
            context.setLineDash([]); // Reset dash

            if (showHandles) {
                // Draw drag handles on the middle of the line
                context.fillStyle = "rgba(255, 255, 255, 0.8)";
                context.arc(canvas.width / 2, linePosition[ImageIndex] * zoom, 30, 0, 2 * Math.PI);
                context.fill();

                // add icon to drag handle
                context.fillStyle = "black";
                context.font = "40px Arial";
                context.textAlign = "center";
                context.textBaseline = "middle";
                // U+2195 is the unicode for up down arrow
                context.fillText("\u2195", canvas.width / 2, linePosition[ImageIndex] * zoom);
            }

        });

        let yellowBands = currentImageBoundingBoxes.filter(([, , , , cls]) => cls === 3);

        let redBands = currentImageBoundingBoxes.filter(([, , , , cls]) => cls === 0);

        const yellowBandGroups = groupBandsByProximity(yellowBands);
        yellowBandGroups.forEach(sortGroupVertically);

        // render measurements for each group of yellow bands
        yellowBandGroups.forEach(group => {
            if (bandMeasurements === undefined) return;

            group.forEach((band, index) => {
                const measurement = bandMeasurements[index] ? bandMeasurements[index] : "";
                const centerX = ((band[0] + band[2]) / 2) * zoom;
                const centerY = ((band[1] + band[3]) / 2) * zoom;

                context.fillStyle = "black";
                // pick font size based on zoom level and box size
                context.font = `${Math.min(20, (band[2] - band[0]) * zoom / 2)}px Arial`;
                context.textAlign = "center";
                context.textBaseline = "middle";
                context.fillText(measurement, centerX, centerY);
            });
        });


        // if red band, find measurement and display it
        if (bandMeasurements !== undefined) {
            // Find all yellow bands and their measurements
            const newMeasurements = HandleRedBands(redBands, currentImageBoundingBoxes, bandMeasurements, yellowBandGroups, class4IndexMap, context);
            updateMeasurements(newMeasurements);
        }
    };

    const updateMeasurements = useCallback((newMeasurements) => {
        setClass0MeasurementsInClass4(prev => {
            const newPrev = { ...prev };
            newPrev[ImageIndex] = newMeasurements;
            return newPrev;
        });
        setCurrentClass0MeasurementsInClass4(newMeasurements);
    }, [ImageIndex]);

    const HandleRedBands = (redBands, currentImageBoundingBoxes, bandMeasurements, yellowBandGroups, class4IndexMap, context) => {
        const newClass0MeasurementsInClass4 = {};
        redBands.forEach((band) => {
            const [xmin, ymin, xmax, ymax] = band;
            if (bandMeasurements.length > 0) {
                const redBandCenterY = (ymin + ymax) / 2;
                const redBandCenterX = (xmin + xmax) / 2;

                // Find the closest yellow band group based on x-coordinate
                const closestGroup = yellowBandGroups.reduce((closest, group) => {
                    const groupCenterX = group.reduce((acc, yellowBand) => acc + getBandCenterX(yellowBand), 0) / group.length;
                    const distance = Math.abs(groupCenterX - redBandCenterX);
                    return distance < closest.distance ? { group, distance } : closest;
                }, { group: null, distance: Infinity }).group;

                if (!closestGroup) {
                    console.error("No yellow band group found for red band");
                    return;
                }
                // Sort the group by vertical position
                closestGroup.sort((a, b) => getBandCenterY(a) - getBandCenterY(b));

                // Find the yellow bands immediately above and below the red band
                const yellowBandAbove = closestGroup.reduce((acc, yellowBand) => {
                    const bandCenterY = getBandCenterY(yellowBand);
                    return bandCenterY < redBandCenterY && bandCenterY > acc[1] ? yellowBand : acc;
                }, [null, -Infinity]);

                const yellowBandBelow = closestGroup.reduce((acc, yellowBand) => {
                    const bandCenterY = getBandCenterY(yellowBand);
                    return bandCenterY > redBandCenterY && bandCenterY < acc[1] ? yellowBand : acc;
                }, [null, Infinity]);

                let redBandValue;
                if (yellowBandAbove && yellowBandBelow) {
                    // find the index of the yellow bands
                    const aboveIndexInGroup = closestGroup.indexOf(yellowBandAbove);
                    const belowIndexInGroup = closestGroup.indexOf(yellowBandBelow);
                    // find index of box in currentImageBoundingBoxes with the same ymin, ymax, xmin, xmax, and class
                    const aboveIndex = currentImageBoundingBoxes.indexOf(yellowBandAbove);
                    const belowIndex = currentImageBoundingBoxes.indexOf(yellowBandBelow);
                    const aboveValue = Number(bandMeasurements[aboveIndexInGroup]);
                    const belowValue = Number(bandMeasurements[belowIndexInGroup]);

                    if (!isNaN(aboveValue) && !isNaN(belowValue)) {
                        const aboveDistance = Math.abs(getBandCenterY(yellowBandAbove) - redBandCenterY);
                        const belowDistance = Math.abs(getBandCenterY(yellowBandBelow) - redBandCenterY);
                        const totalDistance = aboveDistance + belowDistance;
                        redBandValue = ((aboveValue * belowDistance + belowValue * aboveDistance) / totalDistance).toFixed(0);
                    } else if (!isNaN(aboveValue)) {
                        redBandValue = aboveValue.toFixed(0);
                    } else if (!isNaN(belowValue)) {
                        redBandValue = belowValue.toFixed(0);
                    }
                } else {
                    console.error("Red band is not between two yellow bands");
                    // If there's only one yellow band (either above or below), use its value
                    const yellowBand = yellowBandAbove;
                    const index = currentImageBoundingBoxes.indexOf(yellowBand);
                    const value = Number(bandMeasurements[index]);
                    if (!isNaN(value)) {
                        redBandValue = value.toFixed(0);
                    }
                }

                if (redBandValue !== undefined) {
                    // Render the measurement
                    const centerX = ((xmin + xmax) / 2) * zoom;
                    const centerY = ((ymin + ymax) / 2) * zoom;

                    context.fillStyle = "black";
                    context.font = `${Math.min(13, (xmax - xmin) * zoom / 2)}px Arial`;
                    context.textAlign = "center";
                    context.textBaseline = "middle";
                    context.fillText(redBandValue, centerX, centerY);

                    // Find the closest purple band
                    const purpleBands = currentImageBoundingBoxes.filter(([, , , , c]) => c === 2);
                    const closestPurpleBand = purpleBands.reduce((closest, purpleBand) => {
                        const purpleCenterX = (purpleBand[0] + purpleBand[2]) / 2;
                        const distance = Math.abs(purpleCenterX - redBandCenterX);
                        return distance < closest.distance ? { band: purpleBand, distance } : closest;
                    }, { band: null, distance: Infinity }).band;

                    if (closestPurpleBand) {
                        // Find the index of the closest purple band in the sorted array
                        const class4Index = class4IndexMap.get(currentImageBoundingBoxes.indexOf(closestPurpleBand));
                        if (!newClass0MeasurementsInClass4[class4Index]) {
                            newClass0MeasurementsInClass4[class4Index] = [];
                        }
                        newClass0MeasurementsInClass4[class4Index].push(redBandValue);
                    }
                }
            }
        });
        return newClass0MeasurementsInClass4;
    };
    const renderBox = (context, box, color, lineWidth, lineDash) => {
        const [xmin, ymin, xmax, ymax] = box;
        context.strokeStyle = color;
        context.lineWidth = lineWidth;
        context.setLineDash(lineDash);
        context.strokeRect(
            xmin * zoom,
            ymin * zoom,
            (xmax - xmin) * zoom,
            (ymax - ymin) * zoom
        );
        context.setLineDash([]);
    };

    const renderCanvas = useCallback(() => {
        const canvas = canvasRef.current;
        if (!canvas || !base64Image) {
            return;
        }

        const context = canvas.getContext("2d");
        const image = new Image();
        image.src = "data:image/jpg;base64," + base64Image;

        image.onload = () => {
            canvas.width = image.width * zoom;
            canvas.height = image.height * zoom;
            context.clearRect(0, 0, canvas.width, canvas.height);
            context.drawImage(image, 0, 0, canvas.width, canvas.height);


            renderAnnotations(context);

            if (isDragging && dragBox) {
                renderBox(context, dragBox, "white", 2, [5, 5]);
            }

            if (selectedBox) {
                renderBox(context, selectedBox, "white", 2, [5, 5]);
            }
        };
        updateImageHeight();

    }, [base64Image, zoom, isDragging, dragBox, selectedBox, renderAnnotations, selectedWells]);

    const showMeasurementError = (measurements) => {
        // check if measurement is a value between BPValueToFind - BPMarginOfError and BPValueToFind + BPMarginOfError
        if (measurements.length === 0 || BPValueToFind === "") {
            return false;
        }
        var BPInRange = false;
        measurements.forEach(measure => {
            // convert measure to integer
            measure = parseInt(measure);
            let highBound = parseInt(BPValueToFind) + parseInt(BPMarginOfError);
            let lowBound = parseInt(BPValueToFind) - parseInt(BPMarginOfError);
            if (measure >= lowBound && measure <= highBound) {
                BPInRange = true;
            }
            if (measure === parseInt(BPValueToFind)) {
                BPInRange = true;
            }
        });
        return BPInRange;
    };


    useEffect(() => {
        renderCanvas();
    }, [renderCanvas]);

    const findBoxFromCoordinates = useCallback((x, y) => {
        if (setAdjustedBoundingBoxes || savedImageBoundingBoxes) {
            const currentImageBoundingBoxes = isSavedImage ? savedImageBoundingBoxes : adjustedBoundingBoxes[ImageIndex];

            // Find all boxes that contain the clicked point
            const containingBoxes = currentImageBoundingBoxes.filter(([xmin, ymin, xmax, ymax]) =>
                x >= xmin && x <= xmax && y >= ymin && y <= ymax
            );

            // If no boxes contain the point, return null
            if (containingBoxes.length === 0) {
                return null;
            }

            // filter out class 1 boxes
            const filteredBoxes = containingBoxes.filter(([, , , , cls]) => cls !== 1);

            // Sort the containing boxes by area (smallest to largest)
            const sortedBoxes = filteredBoxes.sort((a, b) => {
                const areaA = Math.abs((a[2] - a[0]) * (a[3] - a[1]));
                const areaB = Math.abs((b[2] - b[0]) * (b[3] - b[1]));
                return areaA - areaB;
            });

            // Return the smallest box (first in the sorted array)
            return sortedBoxes[0];
        }
    }, [adjustedBoundingBoxes, ImageIndex, savedImageBoundingBoxes]);


    // Group bands by proximity in terms of x coordinates
    const groupBandsByProximity = (bands, proximityThreshold = 10) => {
        return bands.reduce((groups, band) => {
            const bandCenterX = getBandCenterX(band);
            const existingGroup = groups.find(group => isWithinProximity(group, bandCenterX, proximityThreshold));

            if (existingGroup) {
                existingGroup.push(band);
            } else {
                groups.push([band]);
            }

            return groups;
        }, []);
    };

    const isWithinProximity = (group, bandCenterX, threshold) => {
        const groupCenterX = group.reduce((acc, band) => acc + getBandCenterX(band), 0) / group.length;
        return Math.abs(groupCenterX - bandCenterX) < threshold;
    };

    const getBandCenterX = (band) => (band[0] + band[2]) / 2;
    const getBandCenterY = (band) => (band[1] + band[3]) / 2;

    const sortGroupVertically = (group) => {
        group.sort((a, b) => getBandCenterY(a) - getBandCenterY(b));
    };

    const handleCanvasMouseDown = (e) => {
        const canvas = canvasRef.current;
        if (!canvas) {
            console.log("canvas not found");
            return;
        }


        const rect = canvas.getBoundingClientRect();
        const x = ((e.clientX - rect.left) * (canvas.width / rect.width)) / zoom;
        const y = ((e.clientY - rect.top) * (canvas.height / rect.height)) / zoom;

        if (isDraggingImage) {
            setDisableZoomPan(false);
            setImageBeingDragged(true);
            canvas.style.cursor = "grabbing";
        } else if (linePosition[ImageIndex] !== undefined && Math.abs(y - linePosition[ImageIndex]) < 5) {
            e.preventDefault();
            e.stopPropagation();
            setIsDraggingLine(true);
        } else {
            e.preventDefault();
            e.stopPropagation();
            setDragStartPoint({ x, y });
            setDragBox([x, y, x, y]);
            setIsDragging(true);
        }

    }

    const handleCanvasMouseMove = (e) => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const rect = canvas.getBoundingClientRect();
        const x = ((e.clientX - rect.left) * (canvas.width / rect.width)) / zoom;
        const y = ((e.clientY - rect.top) * (canvas.height / rect.height)) / zoom;

        const boxOnMouse = findBoxFromCoordinates(x, y);
        const lineOnMouse = linePosition[ImageIndex] !== undefined && Math.abs(y - linePosition[ImageIndex]) < 5;
        if (isDraggingImage && imageBeingDragged) {
            canvas.style.cursor = "grabbing";
        } else if (boxOnMouse || lineOnMouse) {
            canvas.style.cursor = "pointer";
            if (lineOnMouse) {
                setShowHandles(true);
            }
        } else {
            setShowHandles(false);
            canvas.style.cursor = "crosshair";
        }

        if (isDraggingLine) {
            e.preventDefault();
            e.stopPropagation();
            setLinePosition(prev => {
                const newPrev = { ...prev };
                newPrev[ImageIndex] = y;
                return newPrev;
            });
            renderCanvas();
        } else if (isDragging) {
            e.preventDefault();
            e.stopPropagation();
            const canvas = canvasRef.current;
            if (!canvas) return;

            const rect = canvas.getBoundingClientRect();
            const x = ((e.clientX - rect.left) * (canvas.width / rect.width)) / zoom;
            const y = ((e.clientY - rect.top) * (canvas.height / rect.height)) / zoom;

            setDragBox(prev => {
                return [prev[0], prev[1], x, y];
            });
        }
    };


    const handleCanvasMouseUp = useCallback((e) => {
        const canvas = canvasRef.current;
        if (!canvas) return;


        const currentImageBoundingBoxes = isSavedImage ? savedImageBoundingBoxes : adjustedBoundingBoxes[ImageIndex];
        // if box is big enough, add to bounding boxes

        setIsDraggingLine(false);
        if (isDraggingImage && imageBeingDragged) {
            setImageBeingDragged(false);
            setDisableZoomPan(true);
            canvas.style.cursor = "grab";
            return;
        }
        if (!isDragging) return;
        e.preventDefault();
        e.stopPropagation();
        const Xmin = Math.min(dragStartPoint.x, dragBox[2]);
        const Xmax = Math.max(dragStartPoint.x, dragBox[2]);
        const Ymin = Math.min(dragStartPoint.y, dragBox[3]);
        const Ymax = Math.max(dragStartPoint.y, dragBox[3]);


        // if box is big enough, add to bounding boxes
        if (Math.abs(Xmax - Xmin) > 5 && Math.abs(Ymax - Ymin) > 5) {
            // add box to bounding boxes
            const newBox = [Xmin, Ymin, Xmax, Ymax, 0, 1];
            if (isSavedImage) {
                setSavedImageBoundingBoxes([...currentImageBoundingBoxes, newBox]);
            }
            else {
                setAdjustedBoundingBoxes(prev => {
                    const newPrev = [...prev];
                    newPrev[ImageIndex] = [...currentImageBoundingBoxes, newBox];
                    return newPrev;
                });
            }
            setDragBox(null);
            setIsDragging(false);
            setSelectedBox(newBox);
        } else // if box is too small, cancel drawing and check if user was trying to click a box
        {
            const clickedBox = findBoxFromCoordinates(Xmin, Ymin);
            if (clickedBox && clickedBox[4] === 2) {
                // if click is inside a well, add to selected wells
                // if well is already selected, deselect it
                // sort purple boxes by x coordinate
                const sortedPurpleBoxes = currentImageBoundingBoxes.filter(([, , , , cls]) => cls === 2).sort((a, b) => a[0] - b[0]);
                const clickedWellIndex = sortedPurpleBoxes.indexOf(clickedBox);
                if (selectedWells.includes(clickedWellIndex)) {
                    // remove from selected wells
                    setSelectedWells(prev => prev.filter(well => well !== clickedWellIndex));
                    console.log("deselecting well" + clickedWellIndex);
                }
                else {
                    setSelectedWells(prev => [...prev, clickedWellIndex]);
                    console.log("selecting well" + clickedWellIndex);
                }

            } else if (clickedBox && selectedBox !== clickedBox && clickedBox[4] !== 2) {
                setSelectedBox(clickedBox);
            } else if (clickedBox && selectedBox === clickedBox) {
                setSelectedBox(null);
            }
            setDisableZoomPan(false);

            setDragBox(null);
            setIsDragging(false);

        }
    }, [dragBox, dragStartPoint, selectedBox, isSavedImage, adjustedBoundingBoxes, ImageIndex, savedImageBoundingBoxes, selectedWells]);

    const handleDeleteBox = () => {
        if (selectedBox) {
            const currentImageBoundingBoxes = isSavedImage ? savedImageBoundingBoxes : adjustedBoundingBoxes[ImageIndex];
            const updatedBoundingBoxes = currentImageBoundingBoxes.filter(box => box !== selectedBox);
            if (isSavedImage) {
                setSavedImageBoundingBoxes(updatedBoundingBoxes);
            } else {
                setAdjustedBoundingBoxes(prev => {
                    const newPrev = [...prev];
                    newPrev[ImageIndex] = updatedBoundingBoxes;
                    return newPrev;
                });
            }

            setSelectedBox(null);
        }
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        if (!isDraggingImage) {
            canvas.addEventListener('mousedown', handleCanvasMouseDown);
            canvas.addEventListener('mousemove', handleCanvasMouseMove);
            canvas.addEventListener('mouseup', handleCanvasMouseUp);


            return () => {
                canvas.removeEventListener('mousedown', handleCanvasMouseDown);
                canvas.removeEventListener('mousemove', handleCanvasMouseMove);
                canvas.removeEventListener('mouseup', handleCanvasMouseUp);
            };
        }
    }, [handleCanvasMouseDown, handleCanvasMouseMove, handleCanvasMouseUp, isDraggingImage]);

    const resetChanges = () => {
        if (isSavedImage) {
            setSavedImageBoundingBoxes(savedImageData.bounding_boxes);
            setBandMeasurements(savedImageData.band_measurements);
        } else {
            const newAdjustedBoundingBoxes = [...adjustedBoundingBoxes];
            newAdjustedBoundingBoxes[ImageIndex] = imageData.bounding_boxes;
            setAdjustedBoundingBoxes(newAdjustedBoundingBoxes);
            setBandMeasurements([]);
        }
        setSelectedBox(null);
        setClass0MeasurementsInClass4({});
        setSelectedWells([0]);
    };

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ width: '100%', p: 2 }}>
                <Tutorial open={tutorialOpen} onClose={() => setTutorialOpen(false)} />
                {message && (
                    <Alert severity={severity} sx={{ mb: 2 }}>
                        {message}
                    </Alert>
                )}
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <Box ref={imageContainerRef}>
                            <TransformWrapper
                                centerOnInit={true}
                                doubleClick={{ disabled: true }}
                                wheel={{ disabled: disableZoomPan, step: 200 }}
                                pan={{ disabled: isDraggingImage }}
                                pinch={{ disabled: disableZoomPan }}
                            >
                                {({ zoomIn, zoomOut }) => (
                                    <>
                                        <Paper ref={containerRef}
                                            sx={{
                                                position: 'relative',
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <TransformComponent

                                            >
                                                <canvas
                                                    ref={canvasRef}
                                                    onMouseDown={handleCanvasMouseDown}
                                                    onMouseMove={handleCanvasMouseMove}
                                                    onMouseUp={handleCanvasMouseUp}
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        maxHeight: '500px'
                                                    }}
                                                />
                                            </TransformComponent>
                                            <Box sx={{ maxWidth: imageWidth, display: 'flex', justifyContent: 'center', gap: 2, width: '100%' }}>
                                                <ToolBar
                                                    toolbarRef={toolbarRef}
                                                    zoomIn={() => zoomIn(1)}
                                                    zoomOut={() => zoomOut(1)}
                                                    resetChanges={resetChanges}
                                                    openTutorial={() => setTutorialOpen(true)}
                                                    handleDeleteBox={() => handleDeleteBox()}
                                                    box={selectedBox}
                                                    handleDraggingImage={() => setIsDraggingImage(!isDraggingImage)}
                                                    isDraggingImage={isDraggingImage}
                                                />
                                            </Box>
                                        </Paper>
                                    </>
                                )}
                            </TransformWrapper>
                        </Box>
                    </Grid>
                    <Grid sx={{
                        width: '100%',
                        height: '100%',
                        maxHeight: imageHeight
                    }} item xs={12} md={4}>
                        <TableContainer component={Paper} sx={{ maxHeight: isSavedImage ? imageHeight - 130 : imageHeight - 75, overflow: 'auto' }}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('eGel-editor.table.wellNumber')}</TableCell>
                                        <TableCell>{t('eGel-editor.table.bandMigration')}</TableCell>
                                        <TableCell>{t('eGel-editor.table.BPInRange')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.entries(currentClass0MeasurementsInClass4).map(([wellNumber, measurements]) => (
                                        <TableRow key={wellNumber}>
                                            <TableCell>{parseInt(wellNumber) + 1}</TableCell>
                                            <TableCell>{measurements.join(", ")}</TableCell>
                                            <TableCell>
                                                {measurements.length > 0 && (
                                                    // for each measurement, check if it is a value within the bp range
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                                        {showMeasurementError(measurements) ? (
                                                            <CheckIcon color="green" />
                                                        ) : (
                                                            <ClearIcon color="error" />
                                                        )}
                                                    </Box>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                                <TemplateLadderDropdown setSelectedTemplateLadder={setSelectedTemplateLadder} selectedTemplateLadder={selectedTemplateLadder} setBandMeasurements={setBandMeasurements} />
                                <FormControl variant="outlined" fullWidth >
                                    <InputLabel>{t("eGel-editor.labels.bpValueToFind")}</InputLabel>
                                    <Input
                                        type="number"
                                        value={BPValueToFind}
                                        onChange={(e) => setBPValueToFind(e.target.value)}
                                    />
                                </FormControl>
                                <FormControl variant="outlined" fullWidth >
                                    <InputLabel>{t("eGel-editor.labels.bpErrorOfMargin")}</InputLabel>
                                    <Input
                                        type="number"
                                        value={BPMarginOfError}
                                        onChange={(e) => setBPMarginOfError(e.target.value)}
                                    />
                                </FormControl>
                            </Box>
                            {isSavedImage && (<ColorButton fullWidth variant="contained" color="primary" onClick={SaveChangesOnImage}> {t('eGel-editor.buttons.save')} </ColorButton>)}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </ThemeProvider>
    );
}

export default GelElectrophoresisEditor;

import { callExternalApi } from "./external-api.service";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;


export const SaveComment = async (formData) => {
  const config = {
    url: `${apiServerUrl}/comment/insertComment`,
    method: "POST",
    headers: {
      "content-type": "multipart/form-data",
    },
    data: formData,
  };
  
  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const GetComments = async (accessToken) => {
  const config = {
    url: `${apiServerUrl}/comment/getComment/`,
    method: "GET",
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const DeleteComment = async (accessToken, formData) => {
    const config = {
      url: `${apiServerUrl}/comment/deleteComment`,
      method: "DELETE",
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      },
      data: formData,
    };
  
    const { data, error } = await callExternalApi({ config });
  
    return {
      data: data || null,
      error,
    };
  }



import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Logo from "../../assets/images/Logos/Logo.png";
import { Button, ThemeProvider } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LanguageSwitch from "../langSwitch/LanguageSwitch";
import Avatar from "@mui/material/Avatar";
import { Typography, SwipeableDrawer } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import "./NavBar.css";
import { ColorButton, theme } from "../../theme";

export default function NavBar({ userData, InfoUpdated }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [sideBarOpen, setSideBarOpen] = useState(false);
    const { user, isAuthenticated } = useAuth0();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const [menuItems, setMenuItems] = useState([]);

    useEffect(() => {
        if (!isAuthenticated) {
            setMenuItems([
                { name: t("navigation.home"), href: "/", current: false, dataTestId: "home" },
                { name: t("navigation.products"), href: "/products", dataTestId: "products", current: false },
                { name: t("navigation.pricing"), href: "/pricing", current: false, dataTestId: "pricing" },
                { name: t("navigation.contact"), href: "/contact", current: false, dataTestId: "contact" },
            ]);
            // set current menu item
            setMenuItems(prevItems => prevItems.map(item => ({
                ...item,
                current: item.href === location.pathname,
            })));
        } else {
            setMenuItems([]);
        }
    }, [isAuthenticated, i18n.language, t]);

    useEffect(() => {
        setMenuItems(prevItems => prevItems.map(item => ({
            ...item,
            current: item.href === location.pathname,
        })));
    }, [location]);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleNavitemClick = (href) => {
        setMenuItems(prevItems => prevItems.map(item => ({
            ...item,
            current: item.href === href,
        })));
        navigate(href);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }
 // if screen size is bigger than 1000px, close the sidebar
 useEffect(() => {
    const handleResize = () => {
        if (window.innerWidth > 1000) {
            setSideBarOpen(false);
        }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
}, []);

return (
    <ThemeProvider theme={theme}>
    <Box data-testid="navbar">
        <AppBar
            position="fixed"
            elevation={0}
            className="top-nav-bar-container"
        >
            <Toolbar className="nav-menu-container">
                    {!isAuthenticated && (
                        <div className="side-bar" style={{ '@media (min-width:1000px)': { display: 'none' } }}>
                            <IconButton
                                size="large"
                                edge="start"
                                color="black"
                                aria-label="open drawer"
                                sx={{ mr: 2, '@media (min-width:1000px)': { display: 'none' } }}
                                onClick={() => setSideBarOpen(!sideBarOpen)}
                            >
                                <MenuIcon />
                            </IconButton>
                            <SwipeableDrawer
                                anchor="left"
                                open={sideBarOpen}
                                onClose={() => setSideBarOpen(false)}
                                onOpen={() => setSideBarOpen(true)}
                                style={{ '@media (min-width:1000px)': { display: 'none' } }}
                            >
                                <div
                                    role="presentation"
                                    onClick={() => setSideBarOpen(false)}
                                    onKeyDown={() => setSideBarOpen(false)}
                                >
                                    <div className="side-bar-header">
                                        <img src={Logo} alt="logo" className="logo" onClick={() => navigate("/home")} />
                                        <div className="close-icon" onClick={() => setSideBarOpen(false)}>
                                            <Typography variant="h6" color="black" style={{ cursor: "pointer" }}>
                                                {t("navigation.close")}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className="side-bar-container">
                                        {menuItems.map((item) => (
                                            <a
                                                className={`nav-menu-item ${item.current ? "active" : ""}`}
                                                aria-current={item.current ? "page" : undefined}
                                                onClick={() => handleNavitemClick(item.href)}
                                                key={item.href}
                                                data-testid={item.dataTestId}
                                            >
                                                {item.name}
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </SwipeableDrawer>
                        </div>
                    )}

                    <img src={Logo} alt="logo" className="logo" onClick={() => navigate("/history")} />
                    {process.env.REACT_APP_ENV === "development" && (
                        <div className="environment-tag">{t("environment.development")}</div> 
                    )}
                    {process.env.REACT_APP_ENV === "staging" && (
                        <div className="environment-tag">{t("environment.staging")}</div> 
                    )}
                    
                    <div className="nav-menu-items" >
                        {menuItems.map((item) => (
                            <a
                                className={`nav-menu-item ${item.current ? "active" : ""}`}
                                aria-current={item.current ? "page" : undefined}
                                onClick={() => handleNavitemClick(item.href)}
                                key={item.href}
                                data-testid={item.dataTestId}
                            >
                                {item.name}
                            </a>
                        ))}
                    </div>
                    <div style={{ flexGrow: 1, marginRight: "10px" }}>
                    <LanguageSwitch />
                    </div>
                    {!isAuthenticated ? (
                        <ColorButton onClick={() => navigate("/login")}>
                            {t("navigation.login")}
                        </ColorButton>
                    ) : (
                        <div>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    textAlign: "center",
                                    cursor: "pointer",
                                    gap: "5px",
                                }}
                                onClick={handleMenu}
                            >
                                <div
                                    style={{
                                        fontSize: "0.8rem",
                                        color: "black",
                                        fontWeight: "bold",
                                        overflow: "hidden",
                                        display: "flex",
                                        flexDirection: "column",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    {userData.name ? userData.name : "user"}

                                    <Typography variant="caption" color="text.secondary">
                                        {t("navigation.freeUser")}
                                    </Typography>
                                </div>
                                <Avatar
                                    alt={user.name}
                                    src={user.picture}
                                    className="profile-icon"
                                />

                                <KeyboardArrowDownIcon color="primary" />
                            </Box>
                            <Menu
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                style={{ padding: "20px" }}
                            >
                                <MenuItem
                                    onClick={() => {
                                        navigate("/profile");
                                        handleClose();
                                    }}
                                    className="profile-icon-dropdown-item"
                                >
                                    <AccountCircleIcon />
                                    {t("navigation.profile")}
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        navigate("/logout");
                                        handleClose();
                                    }}
                                    className="profile-icon-dropdown-item"
                                >
                                    <LogoutIcon />
                                    {t("navigation.logout")}
                                </MenuItem>
                            </Menu>
                        </div>
                    )}
                </Toolbar>
                {(InfoUpdated === false && (userData.name === "" || userData.name === null)) ? (
                    <div style={{ padding: "10px", textAlign: "center", backgroundColor: "#F36E4F", color: "white" }} hidden={InfoUpdated || window.location.pathname === "/profile"}>
                        <p>{t("banner.updateInfo")}</p>
                        <Button onClick={() => navigate("/profile")} style={{ backgroundColor: "white", color: "#F36E4F", marginTop: "10px" }}>
                            {t("banner.updateProfileButton")}
                        </Button>
                    </div>
                ) : null}
            </AppBar>
        </Box>
    </ThemeProvider>
    );
}
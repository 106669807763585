import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    TextField,
    Typography,
    Paper
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import { ColorButton } from '../../../../theme';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import Alert from '@mui/material/Alert';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { BloodSmearRBCTypes } from '../../../../utils/enums';
import './TrialTable.css';

const TrialTable = ({
    OpenImage,
    setOpenDeleteImageConfirmationDialouge,
    setOpenDeleteTrialConfirmationDialouge,
    createCSV,
    trialBeingEdited,
    editMode,
    projectTrials,
    projectImages,
    severity,
    message,
    SetEditing,
    SaveChangesToTrial,
    cancelEdit,
    setTrialDeleteID,
    setImageDeleteID,
    startDates,
    setStartDates,
    endDates,
    setEndDates
}) => {
    const { t } = useTranslation();
    const cellTypes = Object.values(BloodSmearRBCTypes);

    useEffect(() => {
        setStartDates(projectTrials.map((trial) => new Date(trial.start_date)));
        setEndDates(projectTrials.map((trial) => trial.end_date !== '' ? new Date(trial.end_date) : null));
    }, [projectTrials, setStartDates, setEndDates]);

    const splitFilename = (filename) => {
        const lastDotIndex = filename.lastIndexOf('.');
        const name = filename.slice(0, lastDotIndex);
        const extension = filename.slice(lastDotIndex);
        return { name, extension };
    };

    const handleFilenameChange = (image, newName) => {
        const { extension } = splitFilename(image.filename);
        image.filename = `${newName}${extension}`;
    };

    const Row = ({ trial, trialIndex }) => {
        const [open, setOpen] = useState(false);
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(5);
        const isEditing = editMode && trialBeingEdited === trial._id;

        const handleChangePage = (event, newPage) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(+event.target.value);
            setPage(0);
        };

        const toggleOpen = () => setOpen(!open);

        return (
            <React.Fragment>
                <TableRow>
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={toggleOpen}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell
                        component="th"
                        scope="row"
                        onClick={toggleOpen}
                        style={{ cursor: 'pointer', color: '#20B3CE' }}
                    >
                        {isEditing ? (
                            <TextField
                                defaultValue={trial.name}
                                onChange={(e) => trial.name = e.target.value}
                                onClick={(e) => e.stopPropagation()}
                            />
                        ) : (
                            trial.name
                        )}
                    </TableCell>
                    <TableCell>
                        {isEditing ? (
                            <DatePicker
                                value={startDates[trialIndex]}
                                onChange={(date) => setStartDates(startDates.map((startDate, i) => i === trialIndex ? date : startDate))}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        ) : (
                            trial.start_date !== '' ? new Date(trial.start_date).toLocaleDateString() : t('common.notSet')
                        )}
                    </TableCell>
                    <TableCell>
                        {isEditing ? (
                            <DatePicker
                                value={endDates[trialIndex]}
                                onChange={(date) => setEndDates(endDates.map((endDate, i) => i === trialIndex ? date : endDate))}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        ) : (
                            trial.end_date !== '' ? new Date(trial.end_date).toLocaleDateString() : t('common.notSet')
                        )}
                    </TableCell>
                    <TableCell>
                        {isEditing ? (
                            <TextField
                                defaultValue={trial.notes}
                                onChange={(e) => trial.notes = e.target.value}
                            />
                        ) : (
                            trial.notes ? trial.notes : t('common.notSet')
                        )}
                    </TableCell>
                    <TableCell>
                        <div className='trial-table-buttons'>
                            {isEditing ? (
                                <>
                                    <ColorButton variant="contained" onClick={SaveChangesToTrial}>{t('trialTable.save')}</ColorButton>
                                    <ColorButton
                                        style={{
                                            color: '#f36e4f',
                                            border: '1px solid #f36e4f',
                                            backgroundColor: 'white'
                                        }}
                                        onClick={cancelEdit}
                                        onMouseOver={(e) => { e.target.style.backgroundColor = '#f36e4f'; e.target.style.color = 'white'; }}
                                        onMouseOut={(e) => { e.target.style.backgroundColor = 'white'; e.target.style.color = '#f36e4f'; }}
                                    >
                                        {t('trialTable.cancel')}
                                    </ColorButton>
                                    <DeleteForeverIcon
                                        fontSize='large'
                                        onClick={() => {
                                            setOpenDeleteTrialConfirmationDialouge(true);
                                            setTrialDeleteID(trial._id);
                                        }}
                                    />
                                </>
                            ) : (
                                <>
                                    <ColorButton variant="contained" onClick={() => createCSV(trialIndex, projectTrials, projectImages)} className="export-button">{t('trialTable.export')}</ColorButton>
                                    <EditSharpIcon className="edit-icon" onClick={() => { SetEditing(trial._id) }} />
                                </>
                            )}
                        </div>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Table size="small" aria-label="images">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t('trialTable.imageName')}</TableCell>
                                            <TableCell>{t('trialTable.assayType')}</TableCell>
                                            {projectImages[trialIndex].some((image) => image.assay_type !== 'Gel Electrophoresis') && (
                                                <TableCell>{t('trialTable.colonyCount')}</TableCell>
                                            )}
                                            {projectImages[trialIndex].some((image) => image.assay_type === 'GFP/OFP') && (
                                                <React.Fragment>
                                                    <TableCell>{t('trialTable.gfpCount')}</TableCell>
                                                    <TableCell>{t('trialTable.ofpCount')}</TableCell>
                                                </React.Fragment>
                                            )}
                                            {projectImages[trialIndex].some((image) => image.assay_type === 'Membrane') && (
                                                <React.Fragment>
                                                    <TableCell>{t('trialTable.eColiCount')}</TableCell>
                                                    <TableCell>{t('trialTable.otherColiform')}</TableCell>
                                                </React.Fragment>
                                            )}
                                            {cellTypes.map((cellType) => (
                                                projectImages[trialIndex].some((image) => image[cellType]) && (
                                                    <TableCell key={cellType}>{cellType}</TableCell>
                                                )
                                            ))}
                                            {projectImages[trialIndex].some((image) => image.assay_type !== 'Gel Electrophoresis') && (
                                                <>
                                                    <TableCell>{t('trialTable.sensitivity')}</TableCell>
                                                    <TableCell>{t('trialTable.volumePlated')}</TableCell>
                                                    <TableCell>{t('trialTable.dilutionFactor')}</TableCell>
                                                </>
                                            )}
                                            <TableCell>{t('trialTable.notes')}</TableCell>
                                            <TableCell>{isEditing ? t('trialTable.delete') : t('trialTable.timestamp')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {projectImages[trialIndex].slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((image, imageIndex) => (
                                            <TableRow key={imageIndex}>
                                                <TableCell component="th" scope="row" onClick={() => !isEditing && OpenImage(image)} style={{ cursor: isEditing ? 'default' : 'pointer', color: '#20B3CE' }}>
                                                    {isEditing ? (
                                                        <TextField
                                                            defaultValue={splitFilename(image.filename).name}
                                                            onChange={(e) => handleFilenameChange(image, e.target.value)}
                                                        />
                                                    ) : (
                                                        image.filename
                                                    )}
                                                </TableCell>
                                                <TableCell>{image.assay_type}</TableCell>
                                                {projectImages[trialIndex].some((image) => image.assay_type !== 'Gel Electrophoresis') && (
                                                    <TableCell>
                                                        {isEditing ? (
                                                            <TextField
                                                                defaultValue={image.total_detections}
                                                                onChange={(e) => image.total_detections = e.target.value}
                                                            />
                                                        ) : (
                                                            image.total_detections
                                                        )}
                                                    </TableCell>
                                                )}
                                                {projectImages[trialIndex].some((img) => img.assay_type === 'GFP/OFP') && (
                                                    <React.Fragment>
                                                        <TableCell>
                                                            {isEditing ? (
                                                                <TextField
                                                                    defaultValue={image.gfp_counts}
                                                                    onChange={(e) => image.gfp_counts = e.target.value}
                                                                />
                                                            ) : (
                                                                image.gfp_counts ? image.gfp_counts : t('common.notSet')
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            {isEditing ? (
                                                                <TextField
                                                                    defaultValue={image.ofp_counts}
                                                                    onChange={(e) => image.ofp_counts = e.target.value}
                                                                />
                                                            ) : (
                                                                image.ofp_counts ? image.ofp_counts : t('common.notSet')
                                                            )}
                                                        </TableCell>
                                                    </React.Fragment>
                                                )}
                                                {projectImages[trialIndex].some((img) => img.assay_type === 'Membrane') && (
                                                    <React.Fragment>
                                                        <TableCell>
                                                            {isEditing ? (
                                                                <TextField
                                                                    defaultValue={image.eColi_counts}
                                                                    onChange={(e) => image.eColi_counts = e.target.value}
                                                                />
                                                            ) : (
                                                                image.eColi_counts ? image.eColi_counts : t('common.notSet')
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            {isEditing ? (
                                                                <TextField
                                                                    defaultValue={image.other_coliform_counts}
                                                                    onChange={(e) => image.other_coliform_counts = e.target.value}
                                                                />
                                                            ) : (
                                                                image.other_coliform_counts ? image.other_coliform_counts : t('common.notSet')
                                                            )}
                                                        </TableCell>
                                                    </React.Fragment>
                                                )}
                                                {cellTypes.map((cellType) => (
                                                    projectImages[trialIndex].some((img) => img[cellType]) && (
                                                        <TableCell key={cellType}>
                                                            {isEditing ? (
                                                                <TextField
                                                                    defaultValue={image[cellType]}
                                                                    onChange={(e) => image[cellType] = e.target.value}
                                                                />
                                                            ) : (
                                                                image[cellType] ? image[cellType] : 0
                                                            )}
                                                        </TableCell>
                                                    )
                                                ))}
                                                {projectImages[trialIndex].some((image) => image.assay_type !== 'Gel Electrophoresis') && (
                                                    <>
                                                        <TableCell>
                                                            {isEditing ? (
                                                                <TextField
                                                                    defaultValue={image.sensitivity}
                                                                    onChange={(e) => image.sensitivity = e.target.value}
                                                                />
                                                            ) : (
                                                                `${image.sensitivity}%`
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            {isEditing ? (
                                                                <TextField
                                                                    defaultValue={image.volume_plated}
                                                                    onChange={(e) => image.volume_plated = e.target.value}
                                                                />
                                                            ) : (
                                                                `${image.volume_plated ? image.volume_plated : t('common.notSet')} ${image.volume_units ? image.volume_units : ''}`
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            {isEditing ? (
                                                                <TextField
                                                                    defaultValue={image.dilution_factor}
                                                                    onChange={(e) => image.dilution_factor = e.target.value}
                                                                />
                                                            ) : (
                                                                image.dilution_factor ? image.dilution_factor : t('common.notSet')
                                                            )}
                                                        </TableCell>
                                                    </>
                                                )}
                                                <TableCell>
                                                    {isEditing ? (
                                                        <TextField
                                                            defaultValue={image.notes}
                                                            onChange={(e) => image.notes = e.target.value}
                                                        />
                                                    ) : (
                                                        image.notes ? image.notes : t('common.notSet')
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {isEditing ? (
                                                        <DeleteForeverIcon
                                                            fontSize='large'
                                                            onClick={() => {
                                                                setOpenDeleteImageConfirmationDialouge(true);
                                                                setImageDeleteID(image._id);
                                                            }}
                                                        />
                                                    ) : (
                                                        image.timestamp ? new Date(image.timestamp).toLocaleString() : t('common.notSet')
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={projectImages[trialIndex].length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    className="pagination"
                                />
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    };

    return (
        <div className="project-table">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>{t('trialTable.trial')}</TableCell>
                                <TableCell>{t('trialTable.startDate')}</TableCell>
                                <TableCell>{t('trialTable.endDate')}</TableCell>
                                <TableCell>{t('trialTable.notes')}</TableCell>
                                <TableCell>
                                    {severity && message && (<Alert severity={severity}>{message}</Alert>)}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {projectTrials.map((trial, index) => (
                                <Row key={trial._id} trial={trial} trialIndex={index} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </LocalizationProvider>
        </div>
    );
};

export default TrialTable;
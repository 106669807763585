import { createTheme, Button } from "@mui/material";
import { styled } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#FF7F50",
      light: "#FFA07A",
    },
    secondary: {
      light: "#FD9076",
      main: "#F36E4F",
      dark: "#DC5536",
    },
    tertiary: {
      light: "#85FDC2",
      main: "#7FF6BC",
    },
  },
  typography: {
    fontFamily: "Avenir Next Cyr",
    fontSize: 16,
  },
});

export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bottom: "auto",
  transform: "translate(-50%, -50%)",
  width: "60%",
  maxWidth: "99%",
  maxHeight: "97%",
  height: "auto",
  bgcolor: "background.paper",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  overflow: "auto",
  borderRadius: "10px",
  transition: "transform 0.3s ease-out",
  backgroundColor: "white",
};

export const ColorButton = styled(Button)(({ theme }) => ({
  "&.MuiButton-root": {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    padding: "5px 5px",
    fontSize: "16px",
  },
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
  },
  textTransform: "none",
  borderRadius: "7px",
  boxShadow: "none",
  fontFamily: "Avenir Next Cyr",
}));

export const InverseButton = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.main,
  "&.MuiButton-root": {
    backgroundColor: "white",
    color: theme.palette.secondary.main,
  },
  "&:hover": {
    color: theme.palette.secondary.light,
    backgroundColor: "white",
    borderBottom: "1px solid #FD9076",
  },
  textTransform: "none",
  borderRadius: "7px",
  padding: "0px",
  fontFamily: "Avenir Next Cyr",
}));

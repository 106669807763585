import React from "react";
import { useTranslation } from "react-i18next";
import "./About.css"

function About() {
    const { t } = useTranslation();

    return (
        <div className="about-container">
            <img
                src={require("../../../assets/images/Group/group-photo.png")}
                alt={t("about.photoAlt")}
                className="group-photo"
            />
            <p className="about-title">{t("about.title")}</p>
            <p className="about-description">{t("about.description")}</p>
        </div>
    )
}

export default About;
import React, { useState } from "react";
// Auth0
import { useAuth0 } from "@auth0/auth0-react";
// Services
import { SendVerificationEmail } from "../../services/management.service";
// Components
import { Button } from "@mui/material";
// CSS
import "./VerifyEmail.css";
// i18n
import { useTranslation } from 'react-i18next';

export const VerifyEmail = () => {
  const [emailSent, setEmailSent] = useState(false);
  const { user, getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();

  //----------Service Helper Functions----------//
  const resendEmail = async () => {
    if (user) {
      const token = await getAccessTokenSilently();
      try {
        const { data } = await SendVerificationEmail(token, user.sub);
        if (data) {
          setEmailSent(true);
        }
      } catch (e) {
        setEmailSent(false);
        console.error(e);
      }
    }
  };

  return (
    <div className="z-0">
      <div className="z-10 relative">
        <h1 className="text-center text-4xl font-bold py-4 pt-64">
          {t('verifyEmail.welcome')}
        </h1>
        <div className="items-center text-center pb-64 pt-1">
          <p className="font-bold">
            {t('verifyEmail.pleaseVerify')}
          </p>

          {!emailSent ? (
            <div>
              <p className="font-bold mb-4">
                {t('verifyEmail.didNotReceive')}
              </p>
              <Button
                className="resend-email"
                onClick={() => {
                  resendEmail();
                }}
              >
                {t('verifyEmail.resend')}
              </Button>
            </div>
          ) : (
            <div>
              <p className="font-bold mb-4 pt-0 text-green-500">
                {t('verifyEmail.emailSent')}
              </p>
              <p className="font-bold mb-4 pt-0">
                {t('verifyEmail.stillHavingTrouble')}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
